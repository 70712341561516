<template>
  <div class="reservation-container">
    <div class="rsv-top-nav">
      <router-link :to="{name: 'reservation'}">
        <img src="../assets/logo.png" alt="logo" class="logo-tmn" />  
      </router-link>
      <div class="top-line"></div>

      <a href="https://menu.tamani-pos.online" class="rsv-nav"> Our Menu </a>
    </div>

    <div
      v-if="!isFull && ! isBussy"
      class="payment-card">
        <div id="snap-container" 
             style="width: 100%;
             height: 100%">
        </div>
    </div>

    <div
      class="rsv-card"
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 660px;
        overflow: hidden;
        padding: 0;
        border: 0;"
        v-if="isBussy">
      <img 
        src="../assets/server-busy.png" 
        style="position: absolute;
               bottom: 0;
               width: 100%;"
        alt="server-busy">
    </div>

    <div
      class="rsv-card"
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        overflow: hidden;"
        v-if="isFull">
      <div
        class="swal2-icon swal2-error swal2-animate-error-icon"
        style="display: flex;
        transform: scale(1.7);
        margin-top: 40px;">
        <span class="swal2-x-mark"
          ><span class="swal2-x-mark-line-left"></span
          ><span class="swal2-x-mark-line-right"></span
        ></span>
      </div>

      <span class="rsv-title" style="margin-top: 20px;">Reservation Failed!</span>
      <p>{{ isFull }}.</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "ReservasiPaymentView",
  data(){
    return {
      isFull: false,
      isBussy: false,
    }
  },
  created(){
    const url = "https://app.sandbox.midtrans.com/snap/snap.js"
    const clientKey = "SB-Mid-client-N235QOwyraADeOU4"
    const script = document.createElement('script')
    script.src = url
    script.setAttribute('type', "text/javascript")
    script.setAttribute('data-client-key', clientKey)
    script.async = true

    document.body.appendChild(script)
    setTimeout(() => {
        this.generatePayment()
    }, 1000)    
  },
  methods: {
    async generatePayment(){
        try { 
            await axios.get('/generate-payment', {
                headers: { OrderID: this.$route.params.id }
            }).then((response) => {
                if(response.data.data === false){
                  this.isBussy = true;
                } else {
                  this.isBussy = false;
                  const token = response.data.data.token
                  window.snap.embed(token, {
                      embedId: 'snap-container'
                  });
                }
            })
        } catch(error) { 
            if(error.response.status == 406) {
              this.isFull = error.response.data.message
            } else {
              this.$router.push('/')
            }
        }
    }
  }
};
</script>

<style>
.payment-card{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 96svh;
  padding: 0;
  width: 90%;
  max-width: 900px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(3.1px);
  -webkit-backdrop-filter: blur(3.1px);
  border: 1px solid rgba(255, 255, 255, 0.77);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1),
    0 0 20px 1px rgba(255, 255, 255, 0.676), 
    0 0 50px 20px rgb(21, 34, 0);
  font-family: "Finlandica", sans-serif;
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>
