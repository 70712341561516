<script setup>
import TopComp from '@/components/TopComp.vue';
import TableRoom from '@/components/TableRoom.vue';
</script>
<template>
  <div style="position: relative;z-index: 2;">
    <TopComp></TopComp>
  </div>
  <div class="container">
    <img src="../assets/login-bg.png"
    style="position: absolute;
           top:0;left:0;
           z-index: 1;
           width: 100svw;
           height: 100svh;
           object-fit: cover;
           opacity: 0.5;">
    <div style="position: relative;
                z-index: 2;
                overflow-x: hidden;">
      <TableRoom></TableRoom>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Room',
  components: {
    TopComp,
    TableRoom,
  },
};
</script>
