<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="modalPayment"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="modalPaymentLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1
            class="modal-title fs-5"
            id="modalPaymentLabel">
            Menu Payment
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div>
            <div class="form-group mb-3">
              <label
                for="bukti-tf"
                class="form-label fw-bold fs-6"
                >Transfer Evidence
                <span class="text-danger fw-400">
                  (*.jpg, .png, .pdf)</span
                ></label
              >
              <input
                type="file"
                class="form-control"
                id="bukti-tf"
                name="bukti-tf"
                ref="file"
                @change="handleFileChange()"
                accept="image/png,image/jpg,application/pdf" />
              <input
                type="text"
                :value="itemid"
                id="resvid"
                hidden />
            </div>
            <button
              type="submit"
              class="btn btn-primary"
              @click="uploadFile">
              Submit
            </button>
            <div
              v-if="buktiStatusMessage === 200"
              class="text-success fs-6 mt-3">
              {{ buktiMessage }}
            </div>
            <div
              v-if="buktiStatusMessage === 400"
              class="text-danger fs-6 mt-3">
              {{ buktiMessage }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'modalPayment',
  props: ['itemid'],
  data() {
    return {
      selectedFile: null,
      buktiMessage: '',
      buktiStatusMessage: null,
      rsvid: null,
    };
  },
  methods: {
    handleFileChange() {
      this.selectedFile = this.$refs.file.files[0];
      console.log(this.selectedFile);
    },
    async uploadFile() {
      try {
        let formData = new FormData();
        formData.append('attachment', this.selectedFile);
        formData.append('reservid', document.getElementById('resvid').value);
        formData.append('userid', this.$store.getters.GET_AUTH_INFO.id);

        const { data } = await axios.post('/reservasi-confirm', formData, {
          headers: {
            Authorization: this.$store.getters.GET_AUTH_TOKEN,
          },
        });

        this.buktiMessage = 'Bukti transefer berhasil diupload!';
        this.buktiStatusMessage = 200;
        console.log(data);
        setTimeout(() => {
          window.location.reload();
          this.buktiMessage = '';
          this.buktiStatusMessage = null;
        }, 1000);
      } catch (error) {
        console.log(error);
        this.buktiMessage = 'Bukti transefer gagal diupload!';
        this.buktiStatusMessage = 400;
      }
    },
    async closeModal() {
      this.buktiMessage = '';
      this.buktiStatusMessage = null;
    },
  },
};
</script>
