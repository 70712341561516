<template>
  <!-- DataTales Rooms -->
  <div class="card shadow mb-4">
    <div class="card-header py-3">
      <h6 class="m-0 font-weight-bold text-success">Table Room</h6>
    </div>
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-lg-8">
          <!-- Button trigger modal -->
          <button
            type="button"
            class="btn btn-success"
            data-bs-toggle="modal"
            data-bs-target="#ModalCreate">
            Create New Room
          </button>
        </div>
        <div class="col-lg-4">
          <input
            v-model="params.search"
            class="form-control me-2 is-valid"
            type="search"
            placeholder="Search"
            aria-label="Search" />
        </div>
      </div>
      <div class="table-responsive">
        <vue3-datatable
          :search="params.search"
          :rows="rows"
          :columns="cols"
          :totalRows="total_rows"
          :sortable="true"
          :sortColumn="params.sort_column"
          :sortDirection="params.sort_direction">
          <template #edit="data">
            <div class="text-center">
              <a
                class="btn btn-primary text-center"
                data-bs-toggle="modal"
                data-bs-target="#ModalEdit"
                @click="showEdit(data.value)">
                <i class="ri-edit-box-fill fs-6"></i>
              </a>
            </div>
          </template>
          <template #delete="data">
            <div class="text-center">
              <a
                class="btn btn-danger text-center"
                data-bs-toggle="modal"
                data-bs-target="#ModalDelete"
                @click="showDelete(data.value)">
                <i class="ri-calendar-close-fill"></i>
              </a>
            </div>
          </template>
        </vue3-datatable>
      </div>
    </div>
  </div>

  <!-- Modal Create-->
  <div
    class="modal fade"
    id="ModalCreate"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="ModalCreateLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div
        class="modal-content"
        style="
          height: 90vh;
          max-height: 600px;
          overflow-y: scroll;
          overflow-x: hidden;
        ">
        <div class="modal-header">
          <h1
            class="modal-title fs-5"
            id="ModalCreateLabel">
            Form Create Room
          </h1>
          <button
            type="button"
            @click="closeModal"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="mb-3">
              <label
                for="ruang"
                class="form-label"
                >Room Name</label
              >
              <input
                type="text"
                class="form-control"
                id="ruang"
                name="ruang"
                v-model="ruang" />
              <span class="text-danger">{{ isError.ruang }}</span>
            </div>
            <div class="mb-3">
              <label
                for="total_table"
                class="form-label"
                >Total Table</label
              >
              <input
                type="number"
                class="form-control"
                id="total_table"
                name="total_table"
                v-model="total_table" />
              <span class="text-danger">{{ isError.total_table }}</span>
            </div>
            <div class="mb-3">
              <label
                for="capacity"
                class="form-label"
                >Capacity Table</label
              >
              <input
                type="number"
                class="form-control"
                id="capacity"
                name="capacity"
                v-model="capacity" />
              <span class="text-danger">{{ isError.capacity }}</span>
            </div>
            <div class="mb-3">
              <label
                for="minimum_bill"
                class="form-label"
                >Minimum Bill</label
              >
              <input
                type="number"
                class="form-control"
                id="minimum_bill"
                name="minimum_bill"
                v-model="minimum_bill" />
            </div>
            <div class="mb-3">
              <label
                for="down_pay"
                class="form-label"
                >Down Payment Room/Table</label
              >
              <input
                type="number"
                class="form-control"
                id="down_pay"
                name="down_pay"
                v-model="down_pay" />
            </div>
            <div class="mb-3">
              <label
                for="tipe"
                class="form-label"
                >Type</label
              >
              <select
                id="tipe"
                class="form-select"
                v-model="tipe">
                <option value="room">room</option>
                <option value="table">table</option>
              </select>
              <span class="text-danger">{{ isError.tipe }}</span>
            </div>

            <button
              type="submit"
              class="btn btn-success w-100 justify-content-center"
              @click="roomCreate">
              Submit
            </button>
            <div
              v-if="statusMessage === 200"
              class="text-success text-center fs-6 mt-3">
              {{ createMessage }}
            </div>
            <div
              v-if="statusMessage === 400"
              class="text-danger text-center fs-6 mt-3">
              {{ createMessage }}
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            @click="closeModal"
            class="btn btn-secondary"
            data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Edit-->
  <div
    class="modal fade"
    id="ModalEdit"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="ModalEditLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div
        class="modal-content"
        style="
          height: 90vh;
          max-height: 600px;
          overflow-y: scroll;
          overflow-x: hidden;
        ">
        <div class="modal-header">
          <h1
            class="modal-title fs-5"
            id="ModalEditLabel">
            Form Edit Room
          </h1>
          <button
            type="button"
            @click="closeModal"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="mb-3">
              <label
                for="ruang"
                class="form-label"
                >Room Name</label
              >
              <input
                type="text"
                class="form-control"
                id="ruangEdit"
                name="ruang"
                :value="pilihRoom.room" />
              <span class="text-danger">{{ isError.ruang }}</span>
            </div>
            <div class="mb-3">
              <label
                for="total_table"
                class="form-label"
                >Total Table</label
              >
              <input
                type="number"
                class="form-control"
                id="total_tableEdit"
                name="total_table"
                :value="pilihRoom.total_table" />
              <span class="text-danger">{{ isError.total_table }}</span>
            </div>
            <div class="mb-3">
              <label
                for="capacity"
                class="form-label"
                >Capacity Table</label
              >
              <input
                type="number"
                class="form-control"
                id="capacityEdit"
                name="capacity"
                :value="pilihRoom.capacity" />
              <span class="text-danger">{{ isError.capacity }}</span>
            </div>
            <div class="mb-3">
              <label
                for="minimum_bill"
                class="form-label"
                >Minimum Bill</label
              >
              <input
                type="number"
                class="form-control"
                id="minimum_billEdit"
                name="minimum_bill"
                :value="pilihRoom.minimum_bill" />
            </div>
            <div class="mb-3">
              <label
                for="down_pay"
                class="form-label"
                >Down Payment Room/Table</label
              >
              <input
                type="number"
                class="form-control"
                id="down_payEdit"
                name="down_pay"
                :value="pilihRoom.down_pay" />
            </div>
            <div class="mb-3">
              <label
                for="tipe"
                class="form-label"
                >Type</label
              >
              <select
                id="tipeEdit"
                class="form-select"
                :value="pilihRoom.tipe">
                <option value="room">room</option>
                <option value="table">table</option>
              </select>
              <span class="text-danger">{{ isError.tipe }}</span>
            </div>

            <button
              type="submit"
              class="btn btn-success w-100 justify-content-center"
              @click="roomEdit(pilihRoom.id)">
              Save
            </button>
            <div
              v-if="statusMessage === 200"
              class="text-success text-center fs-6 mt-3">
              {{ createMessage }}
            </div>
            <div
              v-if="statusMessage === 400"
              class="text-danger text-center fs-6 mt-3">
              {{ createMessage }}
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            @click="closeModal"
            class="btn btn-secondary"
            data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Delete Message -->
  <div
    class="modal fade"
    id="ModalDelete"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="ModalDeleteLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title fw-bold">
            Are you sure you want to delete {{ pilihRoom.room }} ?
          </h5>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-danger"
            style="width: 100px"
            @click="roomDelete(pilihRoom.id)">
            Yes
          </button>
          <button
            type="button"
            class="btn btn-primary"
            style="width: 100px"
            data-bs-dismiss="modal"
            @click="closeModal()">
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'TableRoom',
  components: {},
  data() {
    return {
      room: [],
      ruang: null,
      total_table: null,
      capacity: null,
      minimum_bill: null,
      down_pay: null,
      tipe: null,
      errorcnt: 0,
      isError: {
        ruang: null,
        total_table: null,
        capacity: null,
        tipe: null,
      },
      createMessage: '',
      statusMessage: null,
      pilihRoom: {},
    };
  },
  mounted() {
    this.getReservasi();
  },
  methods: {
    async getReservasi() {
      try {
        const { data } = await axios.get('/room-branch', {
          headers: {
            Authorization: this.$store.getters.GET_AUTH_TOKEN,
            User: this.$store.getters.GET_AUTH_INFO.id,
          },
        });

        this.room = data.data;
      } catch (error) {
        console.log('404, Get reservasi failed');
        if (error.response.status == 401) {
          store
            .dispatch('LOGOUT')
            .then(() => {
              window.location.href = '/admin-login';
            })
            .catch(() => {
              window.location.href = '/admin-login';
            });
        }
      }
    },

    async roomCreate() {
      try {
        this.isError.ruang = null;
        this.isError.total_table = null;
        this.isError.capacity = null;
        this.isError.tipe = null;
        if (!this.ruang) this.isError.ruang = 'Room name is required';
        if (!this.total_table)
          this.isError.total_table = 'Total table is required';
        if (!this.capacity) this.isError.capacity = 'Capacity is required';
        if (!this.tipe) this.isError.tipe = 'Type is required';
        const createRoom = {
          room: this.ruang,
          total_table: this.total_table,
          capacity: this.capacity,
          minimum_bill: this.minimum_bill,
          down_pay: this.down_pay,
          tipe: this.tipe,
        };
        await axios.post('/room', createRoom, {
          headers: {
            Authorization: this.$store.getters.GET_AUTH_TOKEN,
          },
        });
        this.createMessage = 'Created room successfully!';
        this.statusMessage = 200;
        console.log('200, Created success');
        setTimeout(() => {
          window.location.reload();
          this.createMessage = '';
          this.statusMessage = null;
        }, 1000);
      } catch (error) {
        this.createMessage = 'Created room failed!!!';
        this.statusMessage = 400;
        console.log('400, Created room failed');
      }
    },

    showEdit(roomPilih) {
      this.pilihRoom = {};
      const selected = this.room.filter((data) => {
        return data.id === roomPilih.id;
      });
      this.pilihRoom = selected[0];
    },

    async roomEdit(id) {
      try {
        this.errorcnt = 0;
        this.isError.ruang = null;
        this.isError.total_table = null;
        this.isError.capacity = null;
        this.isError.tipe = null;

        if (!document.getElementById('ruangEdit').value) {
          this.isError.ruang = 'Room name is required';
          this.errorcnt += 1;
        }

        if (!document.getElementById('total_tableEdit').value) {
          this.isError.total_table = 'Total table is required';
          this.errorcnt += 1;
        }

        if (!document.getElementById('capacityEdit').value) {
          this.isError.capacity = 'Capacity is required';
          this.errorcnt += 1;
        }

        if (!document.getElementById('tipeEdit').value) {
          this.isError.tipe = 'Type is required';
          this.errorcnt += 1;
        }

        if (this.errorcnt > 0) {
          throw new Error('');
        }

        const editRoom = {
          id: id,
          room: document.getElementById('ruangEdit').value,
          total_table: parseInt(
            document.getElementById('total_tableEdit').value
          ),
          capacity: parseInt(document.getElementById('capacityEdit').value),
          minimum_bill: parseFloat(
            document.getElementById('minimum_billEdit').value
          ),
          down_pay: parseFloat(document.getElementById('down_payEdit').value),
          tipe: document.getElementById('tipeEdit').value,
        };

        await axios.put('/room', editRoom, {
          headers: {
            Authorization: this.$store.getters.GET_AUTH_TOKEN,
          },
        });

        this.createMessage = 'Edited room successfully!';
        this.statusMessage = 200;
        console.log('200, Edited success');
        setTimeout(() => {
          window.location.reload();
          this.createMessage = '';
          this.statusMessage = null;
        }, 1000);
      } catch (error) {
        this.createMessage = 'Edited room failed!!!';
        this.statusMessage = 400;
        console.log('400, Edited room failed!!!');
      }
    },

    showDelete(roomPilih) {
      this.pilihRoom = {};
      const selected = this.room.filter((data) => {
        return data.id === roomPilih.id;
      });
      this.pilihRoom = selected[0];
    },
    async roomDelete(id) {
      try {
        await axios.delete('/room', {
          headers: {
            Authorization: this.$store.getters.GET_AUTH_TOKEN,
          },
          data: {
            id: id,
          },
        });

        window.location.reload();
      } catch (error) {
        console.log('400, Error Delete!!!');
      }
    },

    async closeModal() {
      this.createMessage = '';
      this.statusMessage = null;
      this.isError.ruang = null;
      this.isError.total_table = null;
      this.isError.capacity = null;
      this.isError.tipe = null;
    },

    /*Membuat Format Decimal*/
    formatDecimal(value) {
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
  },
};
</script>

<script setup>
import { ref, onMounted, reactive } from 'vue';
import Vue3Datatable from '@bhplugin/vue3-datatable';
import '@bhplugin/vue3-datatable/dist/style.css';
import store from '../store';

onMounted(() => {
  getRoom();
});

const params = reactive({
  search: '',
  sort_column: 'index',
  sort_direction: 'asc',
});

const total_rows = ref(0);

const rows = ref(null);

const cols =
  ref([
    { field: 'index', title: 'No', width: '20px' },
    { field: 'room', title: 'Room' },
    { field: 'total_table', title: 'Total Table' },
    { field: 'capacity', title: 'Capacity Table' },
    { field: 'minimum_bill', title: 'Min Bill' },
    { field: 'down_pay', title: 'DP Room/Table' },
    { field: 'tipe', title: 'Type' },
    { field: 'edit', title: 'Edit', width: '20px' },
    { field: 'delete', title: 'Delete', width: '20px' },
  ]) || [];

const getRoom = async () => {
  try {
    const { data } = await axios.get('/room-branch', {
      headers: {
        Authorization: store.getters.GET_AUTH_TOKEN,
        User: store.getters.GET_AUTH_INFO.id,
      },
    });
    const response = await data.data;
    const rooms = [];
    response.forEach((el, index) => {
      const responses = {
        index: index + 1,
        id: el.id,
        room: el.room,
        total_table: el.total_table,
        capacity: el.capacity,
        down_pay: el.down_pay,
        minimum_bill: el.minimum_bill,
        tipe: el.tipe,
        kdcab: el.kdcab,
        isActive: el.isActive,
        createdAt: el.createdAt,
        updateAt: el.updateAt,
        userupdate: el.userupdate,
      };
      rooms.push(responses);
    });
    rows.value = rooms;
    total_rows.value = response.total;
  } catch (error) {
    console.log('400, Error data Table');
    if (error.response.status == 401) {
      store
        .dispatch('LOGOUT')
        .then(() => {
          window.location.href = '/admin-login';
        })
        .catch(() => {
          window.location.href = '/admin-login';
        });
    }
  }
};
</script>

<style scoped>
.form-control.is-valid {
  background-image: none !important;
}
</style>
