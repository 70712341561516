<script setup>
import TopComp from '@/components/TopComp.vue';
import TableUser from '@/components/TableUser.vue';
</script>
<template>
  <div style="position: relative; z-index: 2">
    <TopComp></TopComp>
  </div>
  <div class="container">
    <img
      src="../assets/login-bg.png"
      alt="bg"
      style="
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100svw;
        height: 100svh;
        object-fit: cover;
        opacity: 0.5;
      " />
    <div style="position: relative; z-index: 2; overflow-x: hidden">
      <TableUser></TableUser>
    </div>
  </div>
</template>

<script>
export default {
  name: 'User',
  components: {
    TopComp,
    TableUser,
  },
};
</script>
