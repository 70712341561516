<template>
  <nav class="py-2 bg-body-tertiary border-bottom">
    <div class="container d-flex flex-wrap">
      <ul class="nav me-auto">
        <li class="nav-item">
          <router-link
            class="nav-link"
            active-class="aktif"
            aria-current="page"
            :to="{ name: 'reservasi' }">
            Reservation
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            v-if="roleId === 4 || roleId === 1"
            class="nav-link"
            active-class="aktif"
            aria-current="page"
            :to="{ name: 'validasi' }">
            Validation
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            v-if="roleId === 2 || roleId === 1"
            class="nav-link"
            active-class="aktif"
            aria-current="page"
            :to="'/room'">
            Room
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            v-if="roleId === 1"
            class="nav-link"
            active-class="aktif"
            aria-current="page"
            :to="'/user'">
            User
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            active-class="aktif"
            aria-current="page"
            :to="{ name: 'calendar' }">
            Calendar
          </router-link>
        </li>
      </ul>
      <ul class="nav">
        <li class="nav-item">
          <a
            @click="logout"
            class="btn btn-danger btn-md"
            >Logout</a
          >
        </li>
      </ul>
    </div>
  </nav>
  <header
    class="py-3 mb-4 border-bottom text-white"
    style="background-color: var(--theme-color)">
    <div class="container d-flex flex-wrap justify-content-center">
      <div
        class="d-flex align-items-center mb-3 mb-lg-0 me-lg-auto link-body-emphasis text-decoration-none">
        <span class="fs-6 text-white"
          >Cafe Tamani Tomang ( {{ userid }}-{{ username }} )</span
        >
      </div>
      <div class="col-12 col-lg-auto mb-3 mb-lg-0">
        <span class="fs-6">{{ currentDate() }}</span>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'TopComp',
  data() {
    return {
      userid: null,
      username: null,
      roleId: null,
    };
  },
  mounted() {
    this.userid = this.$store.getters.GET_AUTH_INFO.id;
    this.username = this.$store.getters.GET_AUTH_INFO.name;
    this.roleId = this.$store.getters.GET_AUTH_INFO.role;
  },
  methods: {
    currentDate() {
      const current = new Date();
      const date = `${current.getDate()}/${
        current.getMonth() + 1
      }/${current.getFullYear()}`;
      return date;
    },
    logout() {
      const data = {
        id: String(this.$store.getters.GET_AUTH_INFO.id),
        access_token: this.$store.getters.GET_AUTH_TOKEN,
      };

      this.$store
        .dispatch('LOGOUT', data)
        .then(() => {
          window.location.href = '/admin-login';
        })
        .catch(() => {
          window.location.href = '/admin-login';
        });
    },
  },
};
</script>

<style scoped>
.nav-link.aktif {
  color: var(--theme1-color);
  border-bottom: 2px solid var(--theme1-color);
}

.nav-link {
  color: black;
}
</style>
