<template>
  <div class="container mt-5">
    <img src="../assets/login-bg.png"
    style="position: absolute;
           top:0;left:0;
           z-index: 1;
           width: 100svw;
           height: 100svh;
           object-fit: cover;
           opacity: 0.5;">
    <div style="position: relative;
                z-index: 2;
                overflow-x: hidden;">
      <TableDetail :rsvdetail="reservasi"></TableDetail>
    </div>
  </div>
</template>

<script>
import TableDetail from '@/components/TableDetail.vue';
import axios from 'axios';
export default {
  name: 'Detail',
  components: {
    TableDetail,
  },
  data() {
    return {
      reservasi: {},
    };
  },
  mounted() {
    this.getReservasi();
  },

  methods: {
    async getReservasi() {
      try {
        const { data } = await axios.get('/reservasi-detail', {
          headers: {
            Authorization: this.$store.getters.GET_AUTH_TOKEN,
            ReservID: this.$route.params.id,
          },
        });

        this.reservasi = data.data;
        console.log(this.reservasi);
      } catch (error) {
        if(error.response.status == 401){
            store.dispatch("LOGOUT")
            .then(() => {
                window.location.href = "/admin-login"
            })
            .catch(() => {
                window.location.href = "/admin-login"
            })
        }
      }
    },
  },
};
</script>
