<template>
  <div class="reservation-container">
    <div class="rsv-top-nav">
      <img src="../assets/logo.png" alt="logo" class="logo-tmn" />
      <div class="top-line"></div>

      <a href="https://menu.tamani-pos.online" class="rsv-nav"> Our Menu </a>
    </div>

    <div
      class="rsv-card"
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        overflow: hidden;
      "
    >
      <div
        class="icon icon--order-success svg"
        style="width: 200px; height: 180px"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="72px"
          height="72px"
          style="transform: scale(2); margin-top: 50px"
        >
          <g fill="#f3f3f3" stroke="#8EC343" stroke-width="2">
            <circle
              cx="36"
              cy="36"
              r="35"
              style="stroke-dasharray: 240px, 240px; stroke-dashoffset: 480px"
            ></circle>
            <path
              d="M17.417,37.778l9.93,9.909l25.444-25.393"
              style="stroke-dasharray: 50px, 50px; stroke-dashoffset: 0px"
            ></path>
          </g>
        </svg>
      </div>

      <span class="rsv-title">Reservation Success!</span>
      <p>Your reservation success, Please check your mail for the details!</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ReservasiSuccessView",
  created() {
    this.checkReservation();
  },
  methods: {
    async checkReservation() {
      try {
        const id = this.$route.query.order_id;
        if(!id) this.$router.push("/");

        await axios.get("/reservasi-check", {
          headers: { ID: id },
        }).then((res) => {
          if (res.data.data.status !== "Paid") {
            this.$router.push("/");
          }
        })        
      } catch {
        this.$router.push("/");
      }
    },
  },
};
</script>
