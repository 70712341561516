<template>
  <div class="reservation-container">
    <div class="rsv-top-nav">
        <img src="../assets/logo.png" 
             alt="logo" class="logo-tmn" />
        <div class="top-line"></div>

        <a href="https://menu.tamani-pos.online"
           class="rsv-nav">
          Our Menu
        </a>
    </div>

    <div class="rsv-card" v-if="!completed">
      <span class="rsv-title">Reserve Your Table Now!</span>
      <p>
        Embark on a culinary journey with us! Reserve your table now to indulge
        in a symphony of flavors and impeccable service.
      </p>

      <p class="step-text">Step {{ step }} of {{ endstep }}</p>
      <div class="progress-bar">
        <div
          class="bar-active"
          id="bar-active"
          :style="{ width: barWidth + '%' }"
        ></div>
      </div>

      <div class="form-details" v-if="step === 1">
        <span class="form-title"> Select Branch Restaurant </span>

        <div 
          class="branch-select" 
          v-for="cab in branch" 
          :key="cab.kdcab">
          <input
            type="radio"
            name="branch"
            class="input-radio"
            :value="cab.kdcab"
            v-model="reservasi.kdcab"
          />
          <div
            class="circle"
            :class="{ 'circle-active': reservasi.kdcab === cab.kdcab }"
          ></div>
          <span>{{ cab.company }}</span>
        </div>
        <span class="text-danger">{{ errorCab }}</span>
      </div>

      <div class="form-details" v-if="step === 2">
        <span class="form-title"> Customer Details </span>

        <input
          type="text"
          class="input-form"
          placeholder="Nama Customer"
          v-model="reservasi.atasnama"
        />
        <span class="text-danger">{{ errorName }}</span>

        <input
          type="text"
          class="input-form"
          placeholder="Nomor Telephone / HP"
          v-model="reservasi.notelp"
        />
        <span class="text-danger">{{ errorPhone }}</span>

        <input
          type="email"
          class="input-form"
          placeholder="Email"
          v-model="reservasi.email"
          @change="validateEmail"
        />
        <span class="text-danger">{{ errorEmail }}</span>

        <textarea
          name=""
          id=""
          rows="5"
          class="input-text"
          placeholder="Alamat"
          v-model="reservasi.alamat"
        ></textarea>
        <input
          type="number"
          class="input-form"
          placeholder="Jumlah orang"
          v-model="jumorang"
        />
        <span class="text-danger">{{ errorTtlPerson }}</span>
      </div>

      <div class="form-details" v-if="step === 3">
        <span class="form-title"> Event Details </span>

        <input
          type="text"
          class="input-form"
          placeholder="Nama Acara"
          v-model="reservasi.jenisacara"
        />
        <span class="text-danger">{{ errorAcara }}</span>
        <VDatePicker
          v-model="tglreserv"
          :min-date="mindate"
          style="width: 100%"
        />
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          "
        >
          <div style="width: 48%; display: flex; flex-direction: column">
            <span>Dari Jam</span>
            <input
              type="time"
              class="input-form"
              v-model="jamreserv"
              @change="changeTime"
            />
          </div>
          <div style="width: 48%; display: flex; flex-direction: column">
            <span>Sd Jam</span>
            <input
              type="time"
              class="input-form"
              v-model="reservasi.sdjamrsv"
              readonly
            />
          </div>
        </div>
        <span class="text-danger">*Waktu reservasi maksimal 2 jam, jika ingin waktu tambahan harap hubungi customer service.</span>
        <span class="text-danger">{{ errorTime }}</span>

        <label for="room">Pilih Ruangan</label>
        <select
          name="room"
          id=""
          class="input-form"
          v-model="reservasi.room"
          style="margin-top: -7px">
          <option v-for="room in rooms" :key="room.room" :value="room.room" >{{ room.room }}</option>
        </select>
        <span class="text-danger">{{ errorRoom }}</span>
      </div>

      <div class="form-details" v-if="step === 4 && endstep == 4">
        <span class="form-title"> Payment Details </span>

        <label for="total" style="margin-bottom: -5px">Total Payment</label>
        <input
          type="text"
          name="total"
          class="input-form"
          :value="'Rp. ' + formatDecimal(reservasi.total)"
          readonly
        />

        <label for="dp" style="margin-bottom: -5px">Minimum Down Payment</label>
        <input
          type="text"
          name="dp"
          class="input-form"
          :value="'Rp. ' + formatDecimal(reservasi.downpay)"
          readonly
        />

        <span class="text-danger mt-3">{{ error_minimum }}</span>
      </div>

      <div class="form-details" v-if="step === 4 && endstep === 5">
        <span class="form-title"> Select Food Items </span>

        <div class="food-container">
          <carousel 
              style="width: 100%;"
              :items-to-show="1" 
              :snapAlign="center">
              <slide v-for="slide in categories" :key="slide.id">
                  <div class="category-box" @click="getFoods(slide.id)">
                    <img 
                      :src="getCategoryImage(slide.image)" 
                      style="position: absolute;
                      bottom: 0;
                      width: 100%;
                      object-fit: cover;
                      object-position: center bottom;"
                      :alt="slide.image">
                  </div>
                  <span style="position: absolute;
                  bottom: -22px;z-index: 100;"
                  v-if="swidth < 768">
                    {{ slide.name }}
                  </span>                  
              </slide>

              <template #addons>
                <navigation />
                <pagination />
              </template>
          </carousel>

          <div class="food-card" v-for="fd in foods.menu" :key="fd.id">
            <span class="food-qty" v-if="getQorder(fd.id) > 0">{{
              getQorder(fd.id)
            }}</span>
            <div class="food-img">
              <img
                :src="getFoodImage(fd.image)"
                :alt="fd.image"
                style="width: 100%; height: 100%; object-fit: contain"
              />
            </div>
            <div class="food-details">
              <span class="food-title">{{ fd.name }}</span>
              <span class="food-ctg">{{ foods.name }}</span>
              <span class="food-price">Rp. {{ formatDecimal(fd.price) }}</span>
            </div>

            <div class="food-btn-wrapper">
              <div class="food-sub" @click="subdivCart(fd)">
                <span style="margin-top: -5px">-</span>
              </div>

              <div class="food-qorder">
                  {{ getQorder(fd.id) }}
              </div>

              <div class="food-add" @click="addToCart(fd)">
                <span style="margin-top: -5px">+</span>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="form-details" 
        v-if="step === 5 && endstep == 5">
          <span class="form-title"> Payment Details </span>
          <label for="total" style="margin-bottom: -5px">Total Payment</label>
          <input
            type="text"
            name="total"
            class="input-form"
            :value="'Rp. ' + formatDecimal(reservasi.total)"
            readonly
          />

          <label for="dp" style="margin-bottom: -5px">Minimum Down Payment</label>
          <input
            type="text"
            name="dp"
            class="input-form"
            :value="'Rp. ' + formatDecimal(reservasi.downpay)"
            readonly
          />

          <span class="text-danger mt-3">{{ error_minimum }}</span>
      </div>

      <div class="nav-button-wrapper">
        <button class="prev-button" v-if="step > 1" @click="previousForm">
          Previous
        </button>
        <button class="next-button" @click="processForm">
          <div class="spinner" v-if="isLoadingSubmit">
            <div></div>   
            <div></div>    
            <div></div>    
            <div></div>    
            <div></div>    
            <div></div>    
            <div></div>    
            <div></div>    
            <div></div>    
            <div></div>    
          </div>

          <span v-if="!isLoadingSubmit">
            {{ buttonText }}
          </span>
        </button>
      </div>
    </div>

    <div class="summary-price" v-if="cart.grand > 0">
        <span style="margin-left: 20px;">
          {{ total_items }} items
        </span>
        
        <span style="margin-right: 20px;">
          Rp. {{ formatDecimal(cart.total) }}
        </span>
    </div>

    <div
      class="rsv-card"
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      "
      v-show="completed"
    >
      <div
        class="icon icon--order-success svg"
        style="width: 200px; height: 180px"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="72px"
          height="72px"
          style="transform: scale(2); margin-top: 50px"
        >
          <g fill="#f3f3f3" stroke="#8EC343" stroke-width="2">
            <circle
              cx="36"
              cy="36"
              r="35"
              style="stroke-dasharray: 240px, 240px; stroke-dashoffset: 480px"
            ></circle>
            <path
              d="M17.417,37.778l9.93,9.909l25.444-25.393"
              style="stroke-dasharray: 50px, 50px; stroke-dashoffset: 0px"
            ></path>
          </g>
        </svg>
      </div>

      <span class="rsv-title">Reservation Created!</span>
      <p>Please Wait, Redirecting to Payment Page...</p>
    </div>
  </div>


  <div class="options-container" v-if="showOptions">
    <div class="screenoverlays"></div>
    <div class="options-card">
      <div v-if="gfood.opt1_name !== ''">
        <span class="opt-title"
          >{{ gfood.opt1_name }}<span class="text-danger">*</span></span
        >
        <div class="options-wrapper">
          <div v-for="opt in setOptions" :key="opt.id">
            <div
              class="opt-box"
              :class="{
                'box-act': selectedOpt1 === opt.id,
                'box-unact': selectedOpt1 !== opt.id,
              }"
              v-if="opt.opt === 1"
              @click="setOpt1(opt)">
              <img
                :src="getSauceImg(opt.image)"
                style="width: 90%; object-fit: contain"
                :alt="opt.image"
              />
            </div>
          </div>
        </div>
        <span class="text-danger" style="position: relative; top: -20px">
          {{ errorOpt1 }}
        </span>
      </div>

      <div v-if="gfood.opt2_name !== ''">
        <span class="opt-title"
          >{{ gfood.opt2_name }}<span class="text-danger">*</span></span
        >
        <div class="options-wrapper">
          <div v-for="opt in setOptions" :key="opt.id">
            <div
              class="opt-box"
              :class="{
                'box-act': selectedOpt2 === opt.id,
                'box-unact': selectedOpt2 !== opt.id,
              }"
              v-if="opt.opt === 2"
              @click="setOpt2(opt)"
            >
              <img
                :src="getSauceImg(opt.image)"
                style="width: 90%; object-fit: contain"
                :alt="opt.image"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        style="
          display: flex;
          flex-direction: column;
          font-family: 'Finlandica', sans-serif;
        ">
        <span>Option 1: {{ opt1.name }}</span>
        <span>Option 2: {{ opt2.name }}</span>
      </div>

      <input type="number" v-model="qtyorder" min="1" class="qty-order" />
      <button class="save-button" @click="saveOption(opt1, opt2)">Add</button>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import axios from "axios";

export default {
  name: "ReservasiUserView",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      step: 1,
      endstep: 5,
      buttonText: "Next",
      selectedBranch: null,
      barWidth: 20,
      showFoods: false,
      showOptions: false,
      categories: [],
      foods: [],
      branch: [],
      reservasi: {
        atasnama: null,
        alamat: null,
        notelp: null,
        email: null,
        jumorang: null,
        tglreserv: null,
        jamreserv: null,
        total: null,
        downpay: null,
        sdjamrsv: null,
        jenisrsv: 'Dine In',
        nmanak: null,
        nmortu: null,
        nocard: null,
        jenisacara: null,
        note: null,
        room: null,
        kdcab: null,
        details: {},
      },
      cart: {
        total: 0,
        tax: 0,
        disc: 0,
        scharge: 0,
        grand: 0,
        kdvouch: null,
        items: [],
      },
      completed: false,
      errorCab: null,
      errorName: null,
      errorPhone: null,
      errorEmail: null,
      errorTtlPerson: null,
      errorAcara: null,
      errorTglReserv: null,
      errorTime: null,
      errorJenis: null,
      errorRoom: null,
      errorCard: null,
      setOptions: [],
      mainfood: null,
      gfood: {},
      opt1: {},
      opt2: {},
      fds: {},
      selectedOpt1: null,
      selectedOpt2: null,
      qtyorder: 1,
      errorOpt1: null,
      errorOpt2: null,
      mindate: null,
      message: null,
      rooms: [],
      tglreserv: null,
      jamreserv: null,
      jumorang: null,
      error_minimum: null,
      total_items: 0,
      isLoadingRoom: false,
      isLoadingSubmit: false,
      swidth: 0,
    };
  },
  watch: {
    tglreserv: function(){
        this.getRooms()
    },
    jumorang: function(){
        this.tglreserv = null
        this.jamreserv = null
    }
  },
  mounted() {
    this.swidth = window.screen.width;
    this.getBranch();
    this.getMinimumDate();
  },
  methods: {
    changeTime(){
        this.getRooms()
        const sdjam = parseInt(this.jamreserv.split(":")[0]) + 2
        this.reservasi.sdjamrsv = String(sdjam) + ':' + this.jamreserv.split(":")[1]
    },
    getMinimumDate() {
      let date = new Date();
      this.mindate = new Date(date.setDate(date.getDate() + 1));
      return this.mindate;
    },
    validateEmail() {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.reservasi.email)) {
          this.errorEmail = 'Please enter a valid email address';
      } else {
          this.errorEmail = null;
      }
    },
    async getFoods(id) {
      try {
        this.foods = [];
        const { data } = await axios.get("/category", {
          headers: {
            KodeCab: this.reservasi.kdcab,
            Category: id,
          },
        });

        this.foods = data.data;
        this.showFoods = true;
      } catch (error) {
        throw error;
      }
    },
    async getBranch() {
      try {
        const { data } = await axios.get("/branch");
        this.branch = data.data;
      } catch (error) {
        throw error;
      }
    },
    async getCategories() {
      try {
        const { data } = await axios.get("/categories", {
          headers: { KodeCab: this.reservasi.kdcab },
        });

        this.categories = data.data.filter(category => category.name !== 'SIDE DISH');
        this.endstep = 5
      } catch {
        this.endstep = 4
        this.message = 'Sorry, No Item Available'
      }
    },
    async submitForm() {
      try {
        if(this.isLoadingSubmit) return;
        this.isLoadingSubmit = true

        this.reservasi.details = this.cart;
        await axios.post("/reservasi-user", this.reservasi).then((response) => {
          this.$router.push({name: 'reservation-payment', params: {id: response.data.data.id}})
        })

        this.isLoadingRoom = false;
      } catch (error) {
        this.isLoadingRoom = false;
        throw error;
      }
    },
    async getRooms(){
      try {
        if(this.isLoadingRoom) return;
        this.isLoadingRoom = true

        this.rooms = []
        const newRoom = []
        let time = '01:00'
        if(this.jamreserv) time = this.jamreserv

        const sdate = new Date(Date.parse(this.tglreserv))
        let day = sdate.getDate()
        let month = sdate.getMonth() + 1
        let year = sdate.getFullYear()

        if(day <= 9) day = '0' + String(gday)
        if(month <= 9) month = '0' + String(gmonth)
        
        const header = {
          ReserveDate: String(year) + '-' + String(month) + '-' + String(day)  + ' ' + time + ':00',
          KodeCab: this.reservasi.kdcab,
        }

        const { data } = await axios.get('/room', {
          headers: header
        })

        data.data.forEach((data) => {
          let calculate = Math.floor(parseInt(data.capacity) / parseInt(data.table_capacity))
          let count = Math.floor(this.jumorang / parseInt(data.table_capacity))
          let isDecimal = !Number.isInteger(this.jumorang / parseInt(data.table_capacity))
          if(isDecimal) count += 1

          if(calculate >= count){
            newRoom.push(data)
          }
        })       

        newRoom.forEach((data) => {
          if(data.tipe === 'room'){
            if(data.booked == 0) this.rooms.push(data)
          } else {
            this.rooms.push(data)
          }        
        })

        if(this.rooms.length === 0) this.errorRoom = 'Sorry all room is full at this moment, please select another date!'
        else this.errorRoom = null

        this.isLoadingRoom = false;
      } catch(error){
        console.log(error)
        this.isLoadingRoom = false;
      }
    },
    processForm() {
      if(this.endstep === 5){
        if (this.step >= 5) {
          this.step = 5;
          this.buttonText = "Submit";
          
          const find = this.rooms.filter((data) => {
            return data.room === this.reservasi.room
          })

          if(this.reservasi.total < find[0].minimum_bill){
            this.error_minimum = `Minimum order in ${this.reservasi.room} room is Rp. ${this.formatDecimal(find[0].minimum_bill)}`
            return;
          }

          this.submitForm();
        } else if (this.step === 4) {
          const find = this.rooms.filter((data) => {
            return data.room === this.reservasi.room
          })

          const room = find[0]
          let count = 1;
          if(room.tipe === 'table'){
            count = Math.floor(this.jumorang / parseInt(room.table_capacity))
            const isDecimal = !Number.isInteger(this.jumorang / parseInt(room.table_capacity))
            if(isDecimal) count += 1
          }

          this.reservasi.total = this.cart.grand;
          this.reservasi.downpay = parseInt(room.down_pay) * count;

          this.step += 1;
          this.buttonText = "Submit";
          this.barWidth += 20;
        } else if (this.step === 3) {
          let cnt = 0;
          if (!this.reservasi.jenisacara) {
            this.errorAcara = "Nama Acara harus diisi";
            cnt += 1;
          }

          if (!this.tglreserv) {
            this.errorTglReserv = "Tanggal reservasi harus diisi";
            cnt += 1;
          }

          if (!this.jamreserv || !this.reservasi.sdjamrsv) {
            this.errorTime = "Jam reservasi harus diisi";
            cnt += 1;
          }

          if (!this.reservasi.jenisrsv) {
            this.errorJenis = "Pilih jenis reservasi!";
            cnt += 1;
          }

          if (!this.reservasi.room) {
            this.errorRoom = "Pilih ruangan!";
            cnt += 1;
          }

          if (cnt > 0) return;
          const sdate = new Date(Date.parse(this.tglreserv))
          let day = sdate.getDate()
          let month = sdate.getMonth() + 1
          let year = sdate.getFullYear()
          if(day <= 9) day = '0' + String(gday)
          if(month <= 9) month = '0' + String(gmonth)
          const myDate = String(year) + '-' + String(month) + '-' + String(day)

          this.reservasi.tglreserv = myDate
          this.reservasi.jamreserv = this.jamreserv

          this.step += 1;
          this.buttonText = "Next";
          this.barWidth += 20;
        } else if (this.step === 2) {
          let cnt = 0;
          if (!this.reservasi.atasnama) {
            this.errorName = "Nama customer wajib diisi";
            cnt += 1;
          }

          if (!this.reservasi.notelp) {
            this.errorPhone = "Nomor Telephone wajib diisi";
            cnt += 1;
          }

          if (!this.reservasi.email) {
            this.errorEmail = "Email wajib diisi";
            cnt += 1;
          }

          if (!this.jumorang) {
            this.errorTtlPerson = "Jumlah orang harus diisi";
            cnt += 1;
          }

          if (cnt > 0) return;
          this.reservasi.jumorang = this.jumorang
          this.step += 1;
          this.buttonText = "Next";
          this.barWidth += 20;
        } else if(this.step === 1) {
          this.getCategories();
          if (!this.reservasi.kdcab) {
            this.errorCab = "Pilih Restaurant!";
            return;
          }

          this.step += 1;
          this.buttonText = "Next";
          this.barWidth += 20;
        }
      } else {
        if (this.step >= 4) {
          this.step = 4;
          this.buttonText = "Submit";

          const find = this.rooms.filter((data) => {
            return data.room === this.reservasi.room
          })

          if(this.reservasi.total < find[0].minimum_bill){
            this.error_minimum = `Minimum order in ${this.reservasi.room} room is Rp. ${this.formatDecimal(find[0].minimum_bill)}`
            return;
          }
        
          this.submitForm();
        } else if (this.step === 3) {
          let cnt = 0;

          const find = this.rooms.filter((data) => {
            return data.room === this.reservasi.room
          })

          const room = find[0]
          let count = 1;
          if(room.tipe === 'table'){
            count = Math.floor(this.jumorang / parseInt(room.table_capacity))
            const isDecimal = !Number.isInteger(this.jumorang / parseInt(room.table_capacity))
            if(isDecimal) count += 1
          }

          this.reservasi.total = 0;
          this.reservasi.downpay = parseInt(room.down_pay) * count;
          
          if (!this.reservasi.jenisacara) {
            this.errorAcara = "Nama Acara harus diisi";
            cnt += 1;
          }

          if (!this.tglreserv) {
            this.errorTglReserv = "Tanggal reservasi harus diisi";
            cnt += 1;
          }

          if (!this.jamreserv || !this.reservasi.sdjamrsv) {
            this.errorTime = "Jam reservasi harus diisi";
            cnt += 1;
          }

          if (!this.reservasi.jenisrsv) {
            this.errorJenis = "Pilih jenis reservasi!";
            cnt += 1;
          }

          if (!this.reservasi.room) {
            this.errorRoom = "Pilih ruangan!";
            cnt += 1;
          }

          if (cnt > 0) return;
          const sdate = new Date(Date.parse(this.tglreserv))
          let day = sdate.getDate()
          let month = sdate.getMonth() + 1
          let year = sdate.getFullYear()
          if(day <= 9) day = '0' + String(gday)
          if(month <= 9) month = '0' + String(gmonth)
          const myDate = String(year) + '-' + String(month) + '-' + String(day)

          this.reservasi.tglreserv = myDate
          this.reservasi.jamreserv = this.jamreserv

          this.step += 1;
          this.buttonText = "Next";
          this.barWidth += 25;
        } else if (this.step === 2) {
          let cnt = 0;
          if (!this.reservasi.atasnama) {
            this.errorName = "Nama customer wajib diisi";
            cnt += 1;
          }

          if (!this.reservasi.notelp) {
            this.errorPhone = "Nomor Telephone wajib diisi";
            cnt += 1;
          }

          if (!this.reservasi.email) {
            this.errorEmail = "Email wajib diisi";
            cnt += 1;
          }

          if (!this.jumorang) {
            this.errorTtlPerson = "Jumlah orang harus diisi";
            cnt += 1;
          }

          if (cnt > 0) return;
          this.reservasi.jumorang = this.jumorang
          this.step += 1;
          this.buttonText = "Next";
          this.barWidth += 25;
        } else {
          this.getCategories();
          if (!this.reservasi.kdcab) {
            this.errorCab = "Pilih Restaurant!";
            return;
          }

          this.step += 1;
          this.buttonText = "Next";
          this.barWidth += 30;
        }
      }
    },
    previousForm() {
      this.error_minimum = null
      if (this.step <= 1) this.step = 1;
      else {
        this.step -= 1;
        this.barWidth -= 20;
      }
    },
    closeFood() {
      this.showFoods = false;
      this.foods = [];
    },
    formatDecimal(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getSauceImg(filename) {
      const name = filename.replace(".JPG", ".png");
      return "https://tamani-pos.online/assets/images/options/tamani/" + name;
    },
    getFoodImage(filename) {
      const name = filename.replace(".JPG", ".png");
      return "https://tamani-pos.online/assets/images/food/tamani/" + name;
    },
    getCategoryImage(filename) {
      const name = filename.replace(".JPG", ".jpg");
      return "/images/category/" + name;
    },
    getQorder(id) {
      const find = this.cart.items.filter((element) => {
        return element.id === id;
      });

      let qorder;
      if (find.length > 0) qorder = find[0].qtty;
      else qorder = 0;

      return qorder;
    },
    selectOption(item) {
      const food = {
        id: item.id,
        name: item.name,
        qtty: this.qtyorder,
        price: item.price,
        tax: item.tax,
        ncharge: item.service_charge,
        ndisc: item.discount,
        node: false,
        main_food: false,
        main_id: this.mainfood,
      };

      const find = this.cart.items.filter((data) => {
        return data.id === item.id;
      });

      const idx = this.cart.items.indexOf(find[0]);
      if (find.length > 0) {
        if (this.cart.items[idx].main_id !== this.mainfood) {
          this.cart.items.push(food);
        } else {
          this.cart.items[idx].qtty += parseInt(food.qtty);
        }
      } else {
        this.cart.items.push(food);
      }
    },
    saveOption(opt1, opt2) {
      if (this.gfood.opt1_name !== "" && this.gfood.opt2_name === "") {
        if (!this.selectedOpt1) {
          this.errorOpt1 = `Pilih ${this.gfood.opt1_name}!`;
          return;
        } else {
          Promise.all([this.selectOption(opt1), this.selectOption(opt2)]).then(
            () => {
              this.selectFood(this.fds);
            }
          );

          this.showOptions = false;
        }
      } else if (this.gfood.opt1_name !== "" && this.gfood.opt2_name !== "") {
        if (this.selectedOpt1 && this.selectedOpt2) {
          Promise.all([this.selectOption(opt1), this.selectOption(opt2)]).then(
            () => {
              this.selectFood(this.fds);
            }
          );

          this.showOptions = false;
        }
      } else {
        this.errorOpt1 = `Pilih ${this.gfood.opt1_name}!`;
        this.errorOpt2 = `Pilih ${this.gfood.opt2_name}!`;
        return;
      }
    },
    selectFood(item) {
      const food = {
        id: item.id,
        name: item.name,
        qtty: this.qtyorder,
        price: item.price,
        tax: item.tax,
        ncharge: item.service_charge,
        ndisc: item.discount,
        node: true,
        main_food: true,
        main_id: this.mainfood,
      };

      const find = this.cart.items.filter((data) => {
        return data.id === item.id;
      });

      this.total_items += this.qtyorder;
      const idx = this.cart.items.indexOf(find[0]);
      if (find.length > 0) {
        this.cart.items[idx].qtty += parseInt(food.qtty);
      } else {
        this.cart.items.push(food);
      }

      let total = 0;
      let disc = 0;
      let tax = 0;
      let scharge = 0;
      let grand = 0;
      this.cart.items.forEach((data) => {
        total += data.price * data.qtty;
        disc += ((data.price * data.ndisc) / 100) * data.qtty;
        tax += ((data.price * data.tax) / 100) * data.qtty;
        scharge += data.ncharge * data.qtty;
      });

      grand = total + tax + scharge - disc;
      this.cart.total = total;
      this.cart.tax = tax;
      this.cart.disc = disc;
      this.cart.scharge = scharge;
      this.cart.grand = grand;
    },
    setOpt1(item) {
      this.opt1 = item;
      this.selectedOpt1 = item.id;
    },
    setOpt2(item) {
      this.opt2 = item;
      this.selectedOpt2 = item.id;
    },
    async addToCart(item) {
      this.mainfood = null;
      this.gfood = {};
      const { data } = await axios.get("/menu", {
        headers: {
          KodeCab: this.reservasi.kdcab,
          Kode: item.id,
        },
      });

      this.gfood = data.data;
      if (this.gfood.option.length > 0) {
        this.opt1 = {};
        this.opt2 = {};
        this.fds = {};
        this.selectedOpt1 = null;
        this.selectedOpt2 = null;
        this.qtyorder = 1;

        this.fds = item;
        this.setOptions = this.gfood.option;
        this.mainfood = this.gfood.id;
        this.showOptions = true;
      } else {
        const food = {
          id: item.id,
          name: item.name,
          qtty: 1,
          price: item.price,
          tax: item.tax,
          ncharge: item.service_charge,
          ndisc: item.discount,
          node: true,
          main_food: true,
          main_id: item.id,
        };

        const find = this.cart.items.filter((data) => {
          return data.id === item.id;
        });

        const idx = this.cart.items.indexOf(find[0]);
        if (find.length > 0) {
          this.cart.items[idx].qtty += parseInt(food.qtty);
          this.total_items += 1;
        } else {
          this.cart.items.push(food);
          this.total_items += 1;
        }

        let total = 0;
        let disc = 0;
        let tax = 0;
        let scharge = 0;
        let grand = 0;
        this.cart.items.forEach((data) => {
          total += data.price * data.qtty;
          disc += ((data.price * data.ndisc) / 100) * data.qtty;
          tax += ((data.price * data.tax) / 100) * data.qtty;
          scharge += data.ncharge * data.qtty;
        });

        grand = total + tax + scharge - disc;
        this.cart.total = total;
        this.cart.tax = tax;
        this.cart.disc = disc;
        this.cart.scharge = scharge;
        this.cart.grand = grand;
      }
    },
    subdivCart(item) {
      const find = this.cart.items.filter((data) => {
        return data.main_id === item.id;
      });

      if (find.length > 1) {
        this.total_items -= find[0].qtty;
        find.forEach((data) => {
          const idx = this.cart.items.indexOf(data);
          this.cart.items.splice(idx, 1);
        })
      } else if(find.length > 0 && find.length < 2) {
        this.total_items -= 1;
        const idx = this.cart.items.indexOf(find[0]);
        if (find[0].qtty > 1) {
          this.cart.items[idx].qtty -= 1;
        } else {
          this.cart.items.splice(idx, 1);
        }
      }

      let total = 0;
      let disc = 0;
      let tax = 0;
      let scharge = 0;
      let grand = 0;
      this.cart.items.forEach((data) => {
        total += data.price * data.qtty;
        disc += ((data.price * data.ndisc) / 100) * data.qtty;
        tax += ((data.price * data.tax) / 100) * data.qtty;
        scharge += data.ncharge * data.qtty;
      });

      grand = total + tax + scharge - disc;
      this.cart.total = total;
      this.cart.tax = tax;
      this.cart.disc = disc;
      this.cart.scharge = scharge;
      this.cart.grand = grand;
    },
  },
};
</script>

<style>
@import url("https://fonts.cdnfonts.com/css/unbounded");
@import url("https://fonts.cdnfonts.com/css/finlandica");
@import url("https://fonts.cdnfonts.com/css/expletus-sans-2");
.reservation-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100svw;
  height: 100svh;
  background-image: url("../assets/tmn-bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media(max-width: 768px){
  .reservation-container {
    background-size: 250% 100%;
  }
}

.overlays {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(2.2px);
}

.reservation-container .rsv-card {
  position: relative;
  width: 90%;
  max-width: 900px;
  height: auto;
  max-height: 82%;
  border-radius: 10px;
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(3.1px);
  -webkit-backdrop-filter: blur(3.1px);
  border: 1px solid rgba(255, 255, 255, 0.77);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1),
    0 0 20px 1px rgba(255, 255, 255, 0.676), 
    0 0 50px 20px rgb(21, 34, 0);
  font-family: "Finlandica", sans-serif;
  overflow-x: hidden;
  overflow-y: scroll;
}

.reservation-container .rsv-card .step-text {
  margin-top: 10px;
  font-size: 14pt;
  font-weight: bold;
  margin-bottom: 3px;
}

.reservation-container .rsv-card .rsv-title {
  font-family: "Expletus Sans", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  color: #414141;
}

.reservation-container .rsv-card p {
  font-family: "Finlandica", sans-serif;
  text-align: justify;
}

.reservation-container .rsv-card .progress-bar {
  width: 100%;
  height: 15px;
  border-radius: 50px;
  background: gray;
  padding: 3px 5px;
}

.reservation-container .rsv-card .progress-bar .bar-active {
  width: 20%;
  height: 100%;
  border-radius: 50px;
  background: #446e02;
  background: -webkit-linear-gradient(left, #68a20a, #446e02);
  background: linear-gradient(to left, #68a20a, #446e02);
  transition: all 0.5s ease;
}

.form-details {
  margin-top: 20px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-details .form-title {
  font-family: "Unbounded", sans-serif;
  font-size: 12pt;
  font-weight: bold;
  border-bottom: 3px solid gray;
}

.form-details .input-form {
  width: 100%;
  height: 35px;
  padding: 0 10px;
  background: #f9f9f6;
  border-radius: 5px;
  border: 1px solid #a1a1a1;
}

.form-details .input-text {
  width: 100%;
  padding: 5px 10px;
  background: #f9f9f6;
  border-radius: 5px;
  border: 1px solid #a1a1a1;
}

.nav-button-wrapper {
  position: relative;
  width: 100%;
}

.next-button {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 80px;
  height: 40px;
  border-radius: 5px;
  background: #68a20a;
  color: #fff;
}

.next-button:hover {
  background: #446e02;
}

.prev-button {
  position: absolute;
  bottom: 0;
  right: 90px;
  width: 80px;
  height: 40px;
  border-radius: 5px;
  background: #9a9a9a;
  color: #fff;
}

.prev-button:hover {
  background: #636363;
}

.branch-select {
  position: relative;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #68a20a;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
}

.branch-select .circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #446e02;
}

.branch-select .circle-active {
  background: #446e02;
}

.branch-select .input-radio {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
}

.food-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.food-container .category-box {
  position: relative;
  width: 70%;
  height: 120px;
  background: rgb(215, 215, 215);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #414141;
  cursor: pointer;
  overflow: hidden;
}

.food-wrapper {
  position: fixed;
  width: 96%;
  max-width: 850px;
  height: 540px;
  background: #fff;
  border-radius: 5px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  padding: 20px 15px;
  gap: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.food-card {
  position: relative;
  width: 100%;
  height: 110px;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #f3f3f3;
  box-shadow: 5px 5px 3px -3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.food-card .food-img {
  width: 30%;
  height: 100%;
  overflow: hidden;
}

.food-card .food-qty {
  position: absolute;
  top: 5px;
  left: 10px;
  color: #a92020;
  font-weight: bold;
  font-family: "Finlandica", sans-serif;
}

.food-card .food-details {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
}

.food-card .food-details .food-title {
  font-family: "Expletus Sans", sans-serif;
  font-size: 11pt;
  font-weight: bold;
}

.food-card .food-details .food-ctg {
  font-family: "Finlandica", sans-serif;
  font-size: 10pt;
}

.food-card .food-details .food-price {
  position: absolute;
  bottom: 5px;
  font-family: "Finlandica", sans-serif;
  font-weight: bold;
  margin-top: 10px;
  color: #a92020;
}

.food-btn-wrapper {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 30%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.food-add {
  width: 35%;
  height: 30px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background: transparent;
  border: 1px solid #68a20a;
  color: #68a20a;
  font-size: 20pt;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.food-add:hover {
  background: #446e02;
  color: #fff;
}

.food-qorder{
  width: 30%;
  height: 30px;
  border: 1px solid rgb(64, 64, 64);
  background: #fff8f4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.food-sub {
  width: 35%;
  height: 30px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: transparent;
  border: 1px solid #a2120a;
  color: #a2120a;
  font-size: 20pt;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.food-sub:hover {
  background: rgb(100, 0, 0);
  color: #fff;
}

.options-container {
  position: fixed;
  width: 100svw;
  height: 100svh;
  top: 0;
  left: 0;
  z-index: 101;
}

.options-container .screenoverlays {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000089;
}

.options-container .options-card {
  position: absolute;
  top: 20%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 90%;
  max-width: 400px;
  min-height: 400px;
  background: rgb(243, 243, 243);
  box-shadow: 5px 5px 20px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 15px;
}

.options-container .options-card .options-wrapper {
  width: 90%;
  padding: 10px 0;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  overflow-x: scroll;
}

.options-wrapper .opt-box {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.box-act {
  background: #68a20a;
  color: #fff;
}

.box-unact {
  background: #fff;
}

.opt-title {
  position: relative;
  font-family: "Finlandica", sans-serif;
  font-size: 13pt;
  font-weight: bold;
}

.summary-price{
  position: fixed;
  bottom: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 90%;
  max-width: 900px;
  height: 35px;
  border-radius: 50px;
  background: #98cf00d9;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}

.close-btn {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: rgb(174, 3, 3);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  z-index: 100;
}

.close-btn:hover {
  background: rgb(100, 0, 0);
}

.opt-radio {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
}

@-webkit-keyframes checkmark {
  0% {
    stroke-dashoffset: 50px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@-ms-keyframes checkmark {
  0% {
    stroke-dashoffset: 50px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes checkmark {
  0% {
    stroke-dashoffset: 50px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 240px;
  }

  100% {
    stroke-dashoffset: 480px;
  }
}

@-ms-keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 240px;
  }

  100% {
    stroke-dashoffset: 480px;
  }
}

@keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 240px;
  }

  100% {
    stroke-dashoffset: 480px;
  }
}

.inlinesvg .svg svg {
  display: inline;
}

.icon--order-success svg path {
  -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
  animation: checkmark 0.25s ease-in-out 0.7s backwards;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.icon--order-success svg circle {
  -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
  animation: checkmark-circle 0.6s ease-in-out backwards;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.save-button {
  position: absolute;
  bottom: 7px;
  right: 7px;
  background: #68a20a;
  width: 80px;
  height: 30px;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
}

.save-button:hover {
  background: #446e02;
}

.qty-order {
  position: absolute;
  bottom: 7px;
  right: 90px;
  width: 80px;
  height: 30px;
  border-radius: 5px;
  padding: 0 10px;
  border: 1px solid #68a20a;
}

.rsv-top-nav{
  position: absolute;
  top: 0;
  left: 0;
  width: 100svw;
  height: 180px;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.logo-tmn{
  width: 150px;
  filter: saturate(1.2) brightness(1.2);
  margin-top: 8px;
}

.top-line{
  width: 60%;
  height: .5px;
  background: #787878;
  margin-top: 12px;
}

.rsv-nav{
  text-decoration: none;
  margin-top: 22px;
  color: #fff;
  font-size: 13pt;
  font-family: 'Finlandica', sans-serif;
}

.rsv-nav:hover{
  color: #ff3636;
}

.spinner {
  position: absolute;
  width: 3px;
  height: 3px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.spinner div {
  position: absolute;
  width: 50%;
  height: 150%;
  background: #ffffff;
  transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1%));
  animation: spinner-fzua35 1s calc(var(--delay) * 1s) infinite ease;
}

.spinner div:nth-child(1) {
  --delay: 0.1;
  --rotation: 36;
  --translation: 150;
}

.spinner div:nth-child(2) {
  --delay: 0.2;
  --rotation: 72;
  --translation: 150;
}

.spinner div:nth-child(3) {
  --delay: 0.3;
  --rotation: 108;
  --translation: 150;
}

.spinner div:nth-child(4) {
  --delay: 0.4;
  --rotation: 144;
  --translation: 150;
}

.spinner div:nth-child(5) {
  --delay: 0.5;
  --rotation: 180;
  --translation: 150;
}

.spinner div:nth-child(6) {
  --delay: 0.6;
  --rotation: 216;
  --translation: 150;
}

.spinner div:nth-child(7) {
  --delay: 0.7;
  --rotation: 252;
  --translation: 150;
}

.spinner div:nth-child(8) {
  --delay: 0.8;
  --rotation: 288;
  --translation: 150;
}

.spinner div:nth-child(9) {
  --delay: 0.9;
  --rotation: 324;
  --translation: 150;
}

.spinner div:nth-child(10) {
  --delay: 1;
  --rotation: 360;
  --translation: 150;
}

@keyframes spinner-fzua35 {
  0%, 10%, 20%, 30%, 50%, 60%, 70%, 80%, 90%, 100% {
    transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1%));
  }

  50% {
    transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1.5%));
  }
}
</style>
