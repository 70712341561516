<script setup>
import TopComp from '@/components/TopComp.vue';
import { ref } from 'vue';
import { useScreens } from 'vue-screen-utils';
import axios from 'axios';
import store from '../store';

const { mapCurrent } = useScreens({
  xs: '0px',
  sm: '640px',
  md: '768px',
  lg: '1024px',
});
const columns = mapCurrent({ lg: 2 }, 1);
const expanded = mapCurrent({ lg: false }, true);

const todos = ref([
  {
    highlight: 'blue',
    dates: new Date(),
  },
]);

const reservasi = [];
axios
  .get('/reservasi', {
    headers: {
      Authorization: store.getters.GET_AUTH_TOKEN,
      User: store.getters.GET_AUTH_INFO.id,
    },
  })
  .then((res) => {
    const reserv = res.data.data;
    const newData = reserv.map((data) => ({
      description:
        data.atasnama + ' - ' + data.jenisrsv + ' (' + data.jenisacara + ')',
      isComplete: false,
      dates: new Date(data.tglreserv),
      start: data.jamreserv,
      end: data.sdjamrsv,
      status: data.status,
      color: 'green',
    }));

    newData.forEach((data) => {
      if (data.status === 'Paid') {
        todos.value.push(data);
      }
    });
  })
  .catch((error) => {
    console.error('Error fetching data:', error);
    if(error.response.status == 401){
        store.dispatch("LOGOUT")
        .then(() => {
            window.location.href = "/admin-login"
        })
        .catch(() => {
            window.location.href = "/admin-login"
        })
    }
  });
</script>

<template>
  <div style="position: relative;z-index: 2;">
    <TopComp></TopComp>
  </div>
  <div style="display: flex; flex-direction: column">
    <img src="../assets/login-bg.png"
    style="position: absolute;
          top:0;left:0;
          z-index: 1;
          width: 100svw;
          height: 100svh;
          object-fit: cover;
          opacity: 0.5;">
    <div style="position: relative;
                z-index: 2;
                overflow-x: hidden;">
        <div class="container d-flex justify-content-center mt-5">
          <div style="scale: 150%; margin-top: 20px">
            <VCalendar
              :columns="columns"
              :expanded="expanded"
              :min-date="new Date()"
              :attributes="
                todos.map((todo) => ({
                  dates: todo.dates,
                  highlight: {
                    color: todo.color,
                    ...(todo.isComplete && { class: 'opacity-50' }),
                  },
                  popover: {
                    label: todo.description,
                    visibility: 'focus',
                  },
                }))
              ">
            </VCalendar>
          </div>
        </div>

        <div class="event-wrapper mb-5">
          <h5>Today Event:</h5>
          <div
            v-for="rsv in todos"
            :key="rsv.id">
            <div
              class="event-detail"
              v-show="
                rsv.description &&
                rsv.dates.toLocaleDateString() === new Date().toLocaleDateString()
              "
              :class="{
                'event-active': rsv.dates >= new Date(),
                'event-unactive': rsv.dates < new Date(),
              }">
              <div class="event-name">
                <div
                  :class="{
                    'circle-active': rsv.dates >= new Date(),
                    'circle-unactive': rsv.dates < new Date(),
                  }"></div>

                <span>
                  {{ rsv.description }}
                </span>
              </div>

              <div style="height: 100%">
                <span>{{ rsv.start }} - {{ rsv.end }}</span>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<style>
.event-wrapper {
  position: relative;
  width: 48%;
  margin: 0 auto;
  margin-top: 100px;
}

.event-wrapper .event-detail {
  display: flex;
  justify-content: space-between;
  height: 30px;
}

.event-wrapper .event-detail .event-name {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
}

.event-wrapper .event-detail .event-name .circle-active {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #22c55e;
}

.event-wrapper .event-detail .event-name .circle-unactive {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: rgb(213, 213, 213);
}

.event-active {
  font-weight: bold;
  color: #5a5a5a;
}

.event-unactive {
  font-weight: 400;
  color: #cfcfcf;
}
</style>
