import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';

import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/main.css';

const app = createApp(App);
axios.defaults.baseURL = 'https://tamaniservices.my.id/api/v1';

app.use(store).use(router).use(VCalendar, {}).mount('#app');
