<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="ModalCreate"
    data-bs-backdrop="false"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="ModalCreateLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div
        class="modal-content"
        style="
          height: 90vh;
          overflow-y: scroll;
          overflow-x: hidden;
          z-index: 1000;
          box-shadow: 9px 9px 6px -3px rgba(0, 0, 0, 0.1);
        ">
        <div class="modal-header">
          <h1
            class="modal-title fs-5"
            id="ModalCreateLabel">
            Form Create Reservation
          </h1>
          <button
            type="button"
            @click="closeModal"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <fieldset disabled>
              <div class="row mb-3">
                <div class="col-lg-6">
                  <label
                    for="tgl-input"
                    class="form-label"
                    >Input Date</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="tgl-input"
                    name="tgl-input"
                    placeholder="DD/MM/YYYY" />
                </div>

                <div class="col-lg-6">
                  <label
                    for="input-oleh"
                    class="form-label"
                    >Input By.</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="input-oleh"
                    name="input-oleh"
                    v-model="userinput" />
                </div>
              </div>
            </fieldset>

            <div class="row mb-3">
              <div class="col-lg-4">
                <label
                  for="tgl-resv"
                  class="form-label"
                  >Reservation Date</label
                >
                <input
                  type="date"
                  class="form-control"
                  id="tgl-resv"
                  name="tgl-resv"
                  v-model="tglreserv" />
                <span class="text-danger">{{ error.tglreserv }}</span>
              </div>
              <div class="col-lg-4">
                <label
                  for="jam-resv"
                  class="form-label"
                  >Start Time</label
                >
                <input
                  type="time"
                  class="form-control"
                  id="jam-resv"
                  name="jam-resv"
                  v-model="jamreserv" />
                <span class="text-danger">{{ error.jamreserv }}</span>
              </div>
              <div class="col-lg-4">
                <label
                  for="sd"
                  class="form-label"
                  >End Time</label
                >
                <input
                  type="time"
                  class="form-control"
                  id="sd"
                  name="sd"
                  v-model="sdjam" />
                <span class="text-danger">{{ error.sdjam }}</span>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-lg-6">
                <label
                  for="atas-nama"
                  class="form-label"
                  >On Behalf</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="atas-nama"
                  name="atas-nama"
                  v-model="atasnama" />
                <span class="text-danger">{{ error.atasnama }}</span>
              </div>
              <div class="col-lg-6">
                <label
                  for="telp"
                  class="form-label"
                  >Phone Number</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="telp"
                  name="telp"
                  v-model="notelp" />
                <span class="text-danger">{{ error.notelp }}</span>
              </div>
            </div>

            <div class="mb-3">
              <label
                for="email"
                class="form-label">
                Email
              </label>

              <input
                type="text"
                name="email"
                class="form-control"
                v-model="email" />
              <span class="text-danger">{{ error.email }}</span>
            </div>

            <div class="mb-3">
              <label
                for="alamat"
                class="form-label"
                >Addres</label
              >
              <textarea
                name="alamat"
                class="form-control"
                id="alamat"
                cols="5"
                rows="2"
                v-model="alamat"></textarea>
            </div>

            <div class="row mb-3">
              <div class="col-lg-5">
                <label
                  for="jml-orang"
                  class="form-label"
                  >Number of People</label
                >
                <input
                  type="number"
                  class="form-control"
                  id="jml-orang"
                  name="jml-orang"
                  v-model="jmlorang" />
              </div>
              <div class="col-lg-7">
                <label
                  for="room"
                  class="form-label"
                  >Select Room</label
                >
                <select
                  id="room"
                  class="form-select"
                  v-model="room">
                  <option
                    v-for="room in rooms"
                    :key="room.id"
                    :value="room.room">
                    {{ room.room }}
                  </option>
                </select>
              </div>
            </div>

            <div class="mb-3">
              <label
                for="kdcab"
                class="form-label"
                >Select Branch</label
              >
              <select
                id="kdcab"
                class="form-select"
                v-model="kdcab">
                <option
                  v-for="cab in branch"
                  :key="cab.id"
                  :value="cab.kdcab">
                  {{ cab.kdcab + ' ' + cab.company }}
                </option>
              </select>
              <span class="text-danger">{{ error.kdcab }}</span>
            </div>

            <div class="mb-3">
              <label
                for="jns-resv"
                class="form-label"
                >Reservation Type</label
              >
              <div
                class="btn-group form-control"
                role="group"
                aria-label="Basic radio toggle button group">
                <input
                  type="radio"
                  class="btn-check"
                  name="jns-resv"
                  id="jns-resv1"
                  autocomplete="off"
                  @change="setJenis('Dine In')" />
                <label
                  class="btn btn-outline-primary"
                  for="jns-resv1"
                  >Dine In</label
                >

                <input
                  type="radio"
                  class="btn-check"
                  name="jns-resv"
                  id="jns-resv2"
                  autocomplete="off"
                  @change="setJenis('Take Away')" />
                <label
                  class="btn btn-outline-primary"
                  for="jns-resv2"
                  >Take Away</label
                >
              </div>
              <span class="text-danger">{{ error.jenisreserv }}</span>
            </div>

            <div class="mb-3">
              <label
                for="jns-acara"
                class="form-label"
                >Event Type</label
              >
              <input
                type="text"
                class="form-control"
                id="jns-acara"
                name="jns-acara"
                v-model="jenisacara" />
            </div>

            <div class="mb-3">
              <label
                for="eo"
                class="form-label"
                >EO Collaboration</label
              >
              <div
                class="btn-group form-control"
                role="group"
                aria-label="Basic radio toggle button group">
                <input
                  type="radio"
                  class="btn-check"
                  name="eo"
                  id="eo1"
                  autocomplete="off"
                  @change="setEo(1)" />
                <label
                  class="btn btn-outline-primary"
                  for="eo1"
                  >YES</label
                >
                <input
                  type="radio"
                  class="btn-check"
                  name="eo"
                  id="eo2"
                  autocomplete="off"
                  @change="setEo(0)" />
                <label
                  class="btn btn-outline-primary"
                  for="eo2"
                  >No</label
                >
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-lg-6">
                <label
                  for="nama-anak"
                  class="form-label"
                  >Child's Name</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="nama-anak"
                  name="nama-anak"
                  v-model="nmanak" />
              </div>

              <div class="col-lg-6">
                <label
                  for="nama-ortu"
                  class="form-label"
                  >Parents' Name</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="nama-ortu"
                  name="nama-ortu"
                  v-model="nmortu" />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-lg-6">
                <label
                  for="batas-cancel"
                  class="form-label"
                  >Deadline Cancel</label
                >
                <input
                  type="date"
                  class="form-control"
                  id="batas-cancel"
                  name="batas-cancel"
                  v-model="lastcancel" />
                <span class="text-danger">{{ error.lastcancel }}</span>
              </div>

              <div class="col-lg-6">
                <label
                  for="downpayment"
                  class="form-label"
                  >Down Payment</label
                >
                <input
                  type="number"
                  class="form-control"
                  id="downpayment"
                  name="downpayment"
                  v-model="downpay" />
                <span class="text-danger">{{ error.downpay }}</span>
              </div>
            </div>
            <div class="mb-3">
              <label
                for="note"
                class="form-label"
                >Note</label
              >
              <textarea
                name="note"
                class="form-control"
                id="note"
                cols="5"
                rows="2"
                v-model="note"></textarea>
            </div>

            <fieldset disabled>
              <div class="col-lg-12 mb-3">
                <label
                  for="total"
                  class="form-label"
                  >Total</label
                >
                <input
                  type="number"
                  class="form-control"
                  id="total"
                  name="total" />
              </div>

              <div class="mb-3">
                <label
                  for="pembayaran"
                  class="form-label"
                  >Type Payment</label
                >
                <div
                  class="btn-group form-control"
                  role="group"
                  aria-label="Basic radio toggle button group">
                  <input
                    type="radio"
                    class="btn-check"
                    name="pembayaran"
                    id="jns-pembayaran1"
                    autocomplete="off"
                    @change="setPayment('C')" />
                  <label
                    class="btn btn-outline-primary"
                    for="jns-pembayaran1">
                    Midtrans
                  </label>

                  <input
                    type="radio"
                    class="btn-check"
                    name="pembayaran"
                    id="jns-pembayaran2"
                    autocomplete="off"
                    @change="setPayment('C')" />
                  <label
                    class="btn btn-outline-primary"
                    for="jns-pembayaran2">
                    Cash
                  </label>

                  <input
                    type="radio"
                    class="btn-check"
                    name="pembayaran"
                    id="jns-pembayaran3"
                    autocomplete="off"
                    @change="setPayment('T')" />
                  <label
                    class="btn btn-outline-primary"
                    for="jns-pembayaran3"
                    >Card</label
                  >
                </div>
              </div>

              <div class="mb-3">
                <label
                  for="denda"
                  class="form-label"
                  >Charge</label
                >
                <input
                  type="number"
                  class="form-control"
                  id="denda"
                  name="denda" />
              </div>
            </fieldset>
            <button
              type="submit"
              class="btn btn-success w-100 justify-content-center"
              @click="resvCreate">
              Submit
            </button>
            <div
              v-if="statusMessage === 200"
              class="text-success text-center fs-6 mt-3">
              {{ createMessage }}
            </div>
            <div
              v-if="statusMessage === 400"
              class="text-danger text-center fs-6 mt-3">
              {{ createMessage }}
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            @click="closeModal"
            class="btn btn-secondary"
            data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ModalCreate',
  data() {
    return {
      userinput: this.$store.getters.GET_AUTH_INFO.id,
      tglreserv: null,
      jamreserv: null,
      sdjam: null,
      atasnama: null,
      notelp: null,
      alamat: null,
      jmlorang: 0,
      room: null,
      rooms: [],
      jenisreserv: null,
      jenisacara: null,
      jenisbyr: null,
      byeo: 0,
      nmanak: null,
      nmortu: null,
      lastcancel: null,
      downpay: null,
      kdcab: null,
      note: null,
      branch: [],
      createMessage: '',
      statusMessage: null,
      email: null,
      error: {
        tglreserv: null,
        atasnama: null,
        notelp: null,
        lastcancel: null,
        downpay: null,
        jamreserv: null,
        sdjam: null,
        jenisreserv: null,
        kdcab: null,
        email: null,
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.getBranch();
      this.getRooms();
    }, 1000);
  },
  methods: {
    async getRooms() {
      try {
        const { data } = await axios.get('/room');
        this.rooms = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    setPayment(value) {
      this.jenisbyr = value;
    },
    setJenis(value) {
      this.jenisreserv = value;
    },
    setEo(value) {
      this.byeo = value;
    },
    async getBranch() {
      try {
        const { data } = await axios.get('/branch', {
          headers: {
            Authorization: this.$store.getters.GET_AUTH_TOKEN,
          },
        });

        this.branch = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async resvCreate() {
      try {
        this.error.atasnama = null;
        this.error.notelp = null;
        this.error.tglreserv = null;
        this.error.lastcancel = null;
        this.error.downpay = null;
        this.error.jamreserv = null;
        this.error.sdjam = null;
        this.error.jenisreserv = null;
        this.error.kdcab = null;
        this.error.email = null;

        if (!this.tglreserv)
          this.error.tglreserv = 'Tanggal Reservasi harus diisi';
        if (!this.atasnama) this.error.atasnama = 'Atas nama harus diisi';
        if (!this.notelp) this.error.notelp = 'Nomor telepon harus diisi';
        if (!this.email) this.error.email = 'Email harus diisi';
        if (!this.lastcancel)
          this.error.lastcancel = 'Batas cancel harus diisi';
        if (!this.downpay) this.error.downpay = 'Downpayment harus diisi';
        if (!this.jamreserv) this.error.jamreserv = 'Jam reservasi harus diisi';
        if (!this.sdjam) this.error.sdjam = 's/d jam reservasi harus diisi';
        if (!this.jenisreserv)
          this.error.jenisreserv = 'Jenis reservasi harus diisi';
        if (!this.kdcab) this.error.kdcab = 'Kode cabang harus diisi';

        const reservasi = {
          userid: this.userinput,
          tglreserv: this.tglreserv,
          jamreserv: this.jamreserv,
          sdjamrsv: this.sdjam,
          atasnama: this.atasnama,
          notelp: this.notelp,
          email: this.email,
          alamat: this.alamat,
          jumorang: this.jmlorang,
          room: this.room,
          jenisrsv: this.jenisreserv,
          jenisacara: this.jenisacara,
          byeo: this.byeo,
          nmanak: this.nmanak,
          nmortu: this.nmortu,
          lastcancel: this.lastcancel,
          downpay: this.downpay,
          kdcab: this.kdcab,
          note: this.note,
        };
        await axios.post('/reservasi', reservasi, {
          headers: {
            Authorization: this.$store.getters.GET_AUTH_TOKEN,
          },
        });
        this.createMessage = 'Created reservation successfully!';
        this.statusMessage = 200;
        console.log('200, Created success');
        setTimeout(() => {
          window.location.reload();
          this.createMessage = '';
          this.statusMessage = null;
        }, 1000);
      } catch (error) {
        console.log(error);
        this.createMessage = 'Created reservation failed!!!';
        this.statusMessage = 400;
      }
    },
    async closeModal() {
      this.createMessage = '';
      this.statusMessage = null;
    },
  },
};
</script>
