<template>
  <div class="body text-center" 
    style="height: 100svh;overflow: hidden;">
    <img src="../assets/login-bg.png"
    style="position: absolute;
           top:0;left:0;
           z-index: 1;
           height: 100svh;
           width: 100svw;
           object-fit: cover;
           opacity: 0.5;">
    <main class="form-signin w-100 m-auto" style="z-index: 2;">
      <div>
        <h1 class="h3 mb-3 fw-normal text-success">TAMANI CAFE</h1>
        <div
          v-if="success"
          class="alert alert-success"
          role="alert">
          Login Successfuly!
        </div>
        <div
          v-if="error"
          class="alert alert-danger"
          role="alert">
          {{ message }}
        </div>
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            id="idKey"
            @keyup.enter="login"
            v-model="idKey" />
          <label for="floatingInput">ID Key</label>
        </div>
        <div class="form-floating">
          <input
            type="password"
            class="form-control"
            id="password"
            @keyup.enter="login"
            v-model="password" />
          <label for="floatingPassword">Password</label>
        </div>

        <div class="mt-3 mb-3">
          <div
            v-if="is_proccess"
            class="spinner-border"
            role="status"></div>
          <span
            v-if="is_proccess === false"
            class="text-danger"
            >{{ error_idKey }}</span
          >
          <span
            v-if="is_proccess === false"
            class="text-danger"
            >{{ error_password }}</span
          >
        </div>
        <button
          class="btn-login w-100 btn btn-lg"
          @click="login">
          Login
        </button>
        <p class="mt-3 mb-3 text-muted">v1.0.1 &copy; TAMANI 2023</p>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'LoginView',
  data() {
    return {
      idKey: '',
      password: '',
      error_idKey: '',
      error_password: '',
      success: false,
      error: false,
      message: '',
      is_proccess: false,
    };
  },
  mounted() {},
  methods: {
    login() {
      if (this.is_proccess) return;
      this.error_idKey = '';
      this.error_password = '';
      this.is_proccess = true;

      if (!this.idKey) {
        this.error_idKey = 'ID Key is required!';
        setTimeout(() => {
          this.is_proccess = false;
        }, 2000);
        return;
      }

      if (!this.password) {
        this.error_password = 'Password is required!';
        setTimeout(() => {
          this.is_proccess = false;
        }, 2000);
        return;
      }

      const data = {
        userid: String(this.idKey),
        password: this.password,
      };

      if (this.idKey && this.password) {
        this.$store
          .dispatch('LOGIN', data)
          .then(() => {
            this.success = true;
            setTimeout(() => {
              this.success = false;
              this.$router.push({ name: 'reservasi' });
            }, 2000);
            this.is_proccess = false;
          })
          .catch((error) => {
            this.error = true;
            this.message = error.response.data.message;
            
            setTimeout(() => {
              this.error = false;
            }, 3000);
            this.is_proccess = false;
            throw error;
          });
      }
    },
  },
};
</script>

<style scoped>
.body {
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}
.form-signin {
  max-width: 330px;
  padding: 15px;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type='email'] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type='password'] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-login {
  background-color: var(--theme-color);
  color: #f5f5f5;
}

.btn-login:hover {
  background-color: var(--theme1-color);
  color: #e9e9e9;
}
</style>
