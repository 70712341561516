<template>
  <img
    src="../assets/login-bg.png"
    style="
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100svw;
      height: 100svh;
      object-fit: cover;
      opacity: 0.5;
    "
    alt="bg" />
  <div style="position: relative; z-index: 2; overflow-x: hidden">
    <div class="m-3">
      <div class="row">
        <!-- Total menu -->
        <div class="col-lg-4">
          <div
            class="card shadow h-100 position-fixed"
            style="max-height: 600px; width: 32%">
            <div class="card-header py-3">
              <h6 class="m-0 font-weight-bold text-success">List Item</h6>
            </div>
            <div
              class="card-body"
              style="overflow-y: scroll; overflow-x: hidden">
              <div
                class="table-responsive"
                style="overflow-x: hidden">
                <table
                  class="table table-bordered"
                  id="dataTable"
                  aria-describedby="List Item"
                  style="overflow-x: hidden">
                  <thead>
                    <tr class="text-center">
                      <th>Item</th>
                      <th>Qtty</th>
                      <th>Price</th>
                      <th style="width: 25%">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="menu in smenu"
                      :key="menu.id">
                      <td style="width: 100px">{{ menu.name }}</td>
                      <td class="text-center">
                        {{ menu.qtty }}
                      </td>
                      <td class="text-end">
                        {{ formatDecimal(menu.priceTotal) }}
                      </td>
                      <td class="row">
                        <div class="col-lg-6 text-center">
                          <div
                            class="btn btn-primary"
                            style="scale: 80%"
                            @click="detailItem(menu.id)">
                            <i class="ri-todo-fill"></i>
                          </div>
                        </div>
                        <div
                          class="col-lg-6"
                          style="margin-left: -10px">
                          <div
                            class="btn btn-danger"
                            style="scale: 80%"
                            @click="deleteItem(menu)">
                            <i class="ri-close-fill"></i>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="d-flex justify-content-between mt-2">
                <div class="d-flex"><h6>Total Qtty</h6></div>
                <div class="d-flex">
                  <h6>{{ count.totalQtty }}</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-5"><h6>Sub Total</h6></div>
                <div class="col-lg-2"><h6>Rp.</h6></div>
                <div class="col-lg-5">
                  <h6 class="text-end">{{ formatDecimal(count.subTotal) }}</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-5"><h6>Tax</h6></div>
                <div class="col-lg-2"><h6>Rp.</h6></div>
                <div class="col-lg-5">
                  <h6 class="text-end">{{ formatDecimal(count.tax) }}</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-5"><h6 class="fw-bold">Grand Total</h6></div>
                <div class="col-lg-2"><h6 class="fw-bold">Rp.</h6></div>
                <div class="col-lg-5">
                  <h6 class="text-end fw-bold">
                    {{ formatDecimal(count.grandTotal) }}
                  </h6>
                </div>
              </div>
              <div class="row card-footer">
                <div class="col-lg-6">
                  <div
                    class="btn btn-danger w-100 fw-bold"
                    @click="cancelMenu()">
                    Cancel
                  </div>
                </div>
                <div class="col-lg-6">
                  <div
                    class="btn btn-success w-100 fw-bold"
                    @click="submitDetail">
                    Save
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Category Menu -->
        <div class="col-lg-2">
          <div
            class="card shadow h-100 position-fixed"
            style="max-height: 600px; width: 15.5%">
            <div class="card-header py-3">
              <h6 class="m-0 font-weight-bold text-success">Category Menu</h6>
            </div>
            <div class="card-body">
              <ul
                class="list-group"
                v-for="cat in category"
                :key="cat.id">
                <button
                  type="button"
                  style="height: 60px; font-weight: 600"
                  class="list-group-item list-group-item-action text-center mt-2"
                  :class="{ activecat: cat.id === idcat }"
                  @click="setActiveCategory(cat.id)">
                  {{ cat.name }}
                </button>
              </ul>
            </div>
          </div>
        </div>

        <!-- Detail Menu -->
        <div class="col-lg-6">
          <div
            class="card shadow h-100 position-fixed"
            style="max-height: 600px; width: 48%">
            <div class="card-header py-3">
              <h6 class="m-0 font-weight-bold text-success">Detail Menu</h6>
            </div>
            <div
              class="card-body"
              style="overflow-y: scroll; overflow-x: hidden">
              <div class="row">
                <div
                  class="col-lg-4"
                  v-for="menuItem in menu.menu"
                  :key="menuItem.id">
                  <div
                    class="card foods"
                    @click="selecFood(menuItem.id)">
                    <img
                      :src="getFoodImage(menuItem.image)"
                      class="card-img-top zoom"
                      :alt="menuItem.name" />
                    <div class="card-body text-center">
                      <p class="card-text fw-bold">{{ menuItem.name }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Select Menu -->
    <div
      class="modal"
      :class="{ show: modalMenuItem }">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title fw-bold">{{ menudetail.name }}</h5>
            <button
              type="button"
              class="btn-close"
              @click="closeModal"></button>
          </div>
          <div
            class="modal-body"
            style="overflow-x: hidden">
            <img
              :src="imageMenuDetail"
              class="rounded mx-auto d-block"
              style="width: 175px; height: auto; margin-top: -20px"
              alt="..." />
            <div
              class="input-group w-50"
              style="margin: 0 auto">
              <button
                class="btn btn-outline-success fw-bold"
                type="button"
                @click="kurang"
                :disabled="qtyharga === 1">
                -
              </button>
              <input
                type="text"
                class="form-control text-center"
                min="1"
                v-model="qtyharga"
                :disabled="true" />
              <button
                class="btn btn-outline-success fw-bold"
                type="button"
                @click="tambah">
                +
              </button>
            </div>
            <div
              class="row mt-2"
              v-if="opt1.length > 0">
              <label
                for="sauce"
                class="form-label fs-5 fw-bold"
                >{{ menudetail.opt1_name }}
                <span
                  style="color: red; font-weight: 400; font-size: 15px"
                  v-if="gagalCart.opt1 === 1">
                  * Must be selected
                </span></label
              >
              <div
                class="col-lg-2"
                v-for="item in opt1"
                :key="item.id">
                <div
                  class="btn btn-light"
                  :class="{ activeitem: item.id === idItem }"
                  @click="setActiveItem(item.id)">
                  <img
                    :src="getSauceImg(item.image)"
                    class="rounded"
                    style="width: 50px; height: auto"
                    alt="" />
                </div>
              </div>
              <div
                style="color: red; font-weight: 600; margin-left: 10px"
                :v-model="namaItem">
                {{ namaItem }}
              </div>
            </div>

            <div
              class="row mt-2"
              v-if="opt2.length > 0">
              <label
                for="Side"
                class="form-label fs-5 fw-bold"
                >{{ menudetail.opt2_name }}
                <span
                  style="color: red; font-weight: 400; font-size: 15px"
                  v-if="gagalCart.opt2 === 1">
                  * Must be selected
                </span></label
              >
              <div
                class="col-lg-2"
                v-for="item in opt2"
                :key="item.id">
                <div
                  class="btn btn-light"
                  :class="{ activeitem: item.id === idItem1 }"
                  @click="setActiveItem1(item.id)">
                  <img
                    :src="getSauceImg(item.image)"
                    class="rounded"
                    style="width: 50px; height: auto"
                    alt="" />
                </div>
              </div>
              <div
                style="color: red; font-weight: 600; margin-left: 10px"
                :v-model="namaItem">
                {{ namaItem1 }}
              </div>
            </div>

            <label
              for="matang"
              class="form-label fs-5 fw-bold mt-2"
              v-if="menudetail.is_steak === true"
              >Kematangan
              <span
                style="color: red; font-weight: 400; font-size: 15px"
                v-if="gagalCart.matang === 1">
                * Must be selected
              </span></label
            >
            <div
              class="row"
              v-if="menudetail.is_steak === true">
              <div
                v-for="matang in kematangan"
                :key="matang.id"
                class="btn btn-outline-dark text-start mt-2"
                style="font-weight: 500; width: 455px; margin: 0 auto"
                :class="{ active: matang.id === idMatang }"
                @click="setMatang(matang.id, matang.name)">
                {{ matang.name }}
              </div>
            </div>

            <div class="mt-2">
              <label
                for="notes"
                class="form-label fs-5 fw-bold"
                >Notes</label
              >
              <textarea
                id="notes"
                class="form-control"
                v-model="notes"></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <div
              class="btn btn-success w-100 fw-bold text-center"
              @click="addCart(menudetail.id)">
              <i class="ri-shopping-cart-2-line"></i> Add To Cart
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Detail Item -->
    <div
      class="modal"
      :class="{ show: modalDetailItem }">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title fw-bold">Detail List Menu</h5>
            <button
              type="button"
              class="btn-close"
              @click="closeModal"></button>
          </div>
          <div
            class="modal-body"
            style="overflow-x: hidden">
            <div class="row">
              <div class="col-lg-4">
                <p style="font-weight: 600">{{ detailSmenu.name }}</p>
              </div>
              <div class="col-lg-3">
                <p style="font-weight: 600">
                  {{ formatDecimal(detailSmenu.price) }}
                </p>
              </div>
              <div class="col-lg-2">
                <p style="font-weight: 600">x {{ detailSmenu.qtty }}</p>
              </div>
              <div class="col-lg-3">
                <p style="font-weight: 600">
                  {{ formatDecimal(detailSmenu.priceTotal) }}
                </p>
              </div>
            </div>
            <div
              class="row"
              v-for="opt in detailSmenu.optionSelec"
              :key="opt.id">
              <div class="col-lg-7">
                <p>- {{ opt.name }}</p>
              </div>
              <div class="col-lg-5">
                <p>x {{ opt.qtty }}</p>
              </div>
            </div>
            <p><span class="fw-bold">Notes : </span>{{ detailSmenu.note }}</p>
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>

    <!-- Modal Cancel Message -->
    <div
      class="modal"
      :class="{ show: modalCancel }">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title fw-bold">
              Are you sure you want to cancel the menu you ordered ?
            </h5>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              style="width: 100px"
              @click="this.$router.go(-1)">
              Yes
            </button>
            <button
              type="button"
              class="btn btn-primary"
              style="width: 100px"
              @click="closeModal()">
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import store from '../store';

export default {
  name: 'Menu',
  components: {},
  data() {
    return {
      /*
        idCat = Category id (Aktif kategori),
        qtyharga = quantity select food,
        smenu = Array food select yang sudah di simpan di cart,
        category = Array list category,
        menu = array list menu berdasarkan kategori,
        menudetail = data menu yang di select
        imageMenuDetail = mengambil data img dari menudetail
        modalMenuItem = untuk memunculkan modal menudetail
        notes = mengambil data note dari input notes
        opt1 = mengambil data array menudetail option opt1
        opt2 = mengambil data array menudetail option opt2
        idItem = mengambil idItem atau id opt1
        namaItem = mengambil nama item atau name opt1
        selectOpt = mengambil data opt1 untuk di simpan di option terlebih dahulu
        idItem1 = mengambil idItem atau id opt2
        namaItem1 = mengambil nama item atau name opt2
        selectOpt1 = mengambil data opt2 untuk di simpan di option terlebih dahulu
        option = data array yang didapat dari selectOpt1 dan selectOpt2
        kematangan =  data hardcore untuk kematangan pada steak
        matangSelec = untuk mengambil kematangan yang dipilih
        idMatang = mengambil data id dari kematangan.id
        detailSmenu = mengambil dan menampilkan data yang dipilih dari smenu
        modaldetailitem = untuk menampilkan modal detail item di list menu kasir
        gagalCart = data validasi untuk menu yang ingin di masukan ke cart
        count = data hitungan item yang ada di list menu kasir
      */
      idcat: 1,
      qtyharga: 1,
      smenu: [],
      category: [],
      menu: [],
      menudetail: {},
      imageMenuDetail: null,
      modalMenuItem: false,
      notes: '',
      opt1: [],
      opt2: [],
      idItem: null,
      namaItem: null,
      selectOpt: null,
      idItem1: null,
      namaItem1: null,
      selectOpt1: null,
      option: [],
      find: [],
      kematangan: [
        {
          id: 1,
          name: 'Rare',
        },
        {
          id: 2,
          name: 'Medium Rare',
        },
        {
          id: 3,
          name: 'Medium',
        },
        {
          id: 4,
          name: 'Medium Well',
        },
        {
          id: 5,
          name: 'Well Done',
        },
        {
          id: 6,
          name: 'Very Well Done',
        },
      ],
      matangSelec: null,
      idMatang: null,
      detailSmenu: {},
      modalDetailItem: false,
      gagalCart: {
        opt1: null,
        opt2: null,
        matang: null,
      },
      count: {
        totalQtty: 0,
        subTotal: 0,
        tax: 0,
        grandTotal: 0,
      },
      modalCancel: false,
    };
  },
  mounted() {
    this.initialCategory();
  },
  methods: {
    /*Validasi Cancel Menu*/
    cancelMenu() {
      if (this.smenu.length > 0) {
        this.modalCancel = true;
      } else {
        this.$router.go(-1);
      }
    },

    /*Methode untuk aktiv kategori dan menampilkan menu sesuai kategori*/
    async setActiveCategory(id) {
      this.idcat = id;
      try {
        const { data } = await axios.get('/category', {
          headers: {
            Authorization: this.$store.getters.GET_AUTH_TOKEN,
            KodeCab: 'J07',
            Category: id,
          },
        });

        this.menu = data.data;
      } catch (error) {
        console.log(error);
      }
    },

    /*Methode untuk aktiv item/option1 dan membuat data selectOpt*/
    setActiveItem(id) {
      this.idItem = null;
      this.namaItem = null;
      this.idItem = id;
      this.selectOpt = null;

      this.opt1.forEach((el) => {
        if (el.id === this.idItem) {
          this.namaItem = el.name;
          this.selectOpt = {
            id: el.id,
            name: el.name,
            opt: el.opt,
            price: el.price,
            qtty: null,
          };
        }
      });
    },

    /*Methode untuk aktiv item/option2 dan membuat data selectOpt1*/
    setActiveItem1(id) {
      this.idItem1 = null;
      this.namaItem1 = null;
      this.idItem1 = id;
      this.selectOpt1 = null;

      this.opt2.forEach((el) => {
        if (el.id === this.idItem1) {
          this.namaItem1 = el.name;
          this.selectOpt1 = {
            id: el.id,
            name: el.name,
            opt: el.opt,
            price: el.price,
            qtty: null,
          };
        }
      });
    },

    /*Methode untuk aktiv kematangan dan membuat data matangSelec*/
    setMatang(id, name) {
      this.idMatang = null;
      this.idMatang = id;
      this.matangSelec = name;
    },

    /*Membuat data category*/
    async initialCategory() {
      try {
        const { data } = await axios.get('/categories', {
          headers: {
            KodeCab: 'J07',
            Authorization: this.$store.getters.GET_AUTH_TOKEN,
          },
        });

        this.category = data.data;
        this.setActiveCategory(this.category[0].id);
      } catch (error) {
        console.log(error);
        if (error.response.status == 401) {
          store
            .dispatch('LOGOUT')
            .then(() => {
              window.location.href = '/admin-login';
            })
            .catch(() => {
              window.location.href = '/admin-login';
            });
        }
      }
    },

    /*Meraplace gambar jpg to png untuk di tampilkan di menu*/
    getFoodImage(filename) {
      const name = filename.replace('.JPG', '.png');
      return 'https://tamani-pos.online/assets/images/food/tamani/' + name;
    },

    /*Membuat data menudetail dari menu yang diselec*/
    async selecFood(id) {
      try {
        this.menudetail = {};
        this.menudetailOpt = [];
        this.opt1 = [];
        this.opt2 = [];
        this.selectOpt = null;
        this.selectOpt1 = null;
        this.matangSelec = '';

        const { data } = await axios.get('/menu', {
          headers: {
            Authorization: this.$store.getters.GET_AUTH_TOKEN,
            KodeCab: this.$store.state.kodecabang,
            Kode: id,
          },
        });

        this.menudetail = data.data;

        const name = this.menudetail.image.replace('.JPG', '.png');
        this.imageMenuDetail =
          'https://tamani-pos.online/assets/images/food/tamani/' + name;

        if (this.menudetail.option.length > 0) {
          this.menudetail.option.forEach((el) => {
            if (el.opt === 1) {
              this.opt1.push(el);
            } else {
              this.opt2.push(el);
            }
          });
        }

        this.modalMenuItem = true;
      } catch (error) {
        console.log(error);
      }
    },

    /*Membuat data smenu dari form menudetail yang sudah di input, ditampilkan dilist menu kasir*/
    addCart(id) {
      this.find = [];
      this.option = [];
      this.gagalCart.opt1 = null;
      this.gagalCart.opt2 = null;
      this.gagalCart.matang = null;

      if (this.menudetail.is_steak === true) {
        if (this.selectOpt === null) {
          this.gagalCart.opt1 = 1;
        }

        if (this.selectOpt1 === null) {
          this.gagalCart.opt2 = 1;
        }

        if (!this.matangSelec) {
          this.gagalCart.matang = 1;
        }

        if (
          this.gagalCart.opt1 === 1 ||
          this.gagalCart.opt2 === 1 ||
          this.gagalCart.matang === 1
        ) {
          return;
        }
      }

      if (this.selectOpt !== null) {
        this.selectOpt.qtty = this.qtyharga;
        this.option.push(this.selectOpt);
      }
      if (this.selectOpt1 !== null) {
        this.selectOpt1.qtty = this.qtyharga;
        this.option.push(this.selectOpt1);
      }

      let catat;
      if (this.matangSelec) {
        catat =
          this.matangSelec + '(' + this.qtyharga + ')' + ', ' + this.notes;
      } else catat = '';

      const save = {
        id: this.menudetail.id,
        name: this.menudetail.name,
        price: this.menudetail.price,
        priceTotal: this.menudetail.price * this.qtyharga,
        tax: this.menudetail.tax,
        optionSelec: this.option,
        note: catat,
        qtty: this.qtyharga,
      };

      if (save.length !== 0) {
        if (this.smenu.length <= 0) {
          this.smenu.push(save);
          this.closeModal();
          this.countKasir();
        } else {
          this.find = this.smenu.filter((element) => {
            return element.id === save.id;
          });

          let index = this.smenu.indexOf(this.find[0]);
          if (this.find.length > 0) {
            if (save.optionSelec.length <= 0) {
              this.smenu[index].qtty = this.smenu[index].qtty + save.qtty;
              this.smenu[index].priceTotal =
                this.smenu[index].price * this.smenu[index].qtty;
              this.smenu[index].note += save.note;
            } else if (save.optionSelec.length === 1) {
              this.smenu[index].qtty = this.smenu[index].qtty + save.qtty;
              this.smenu[index].priceTotal =
                this.smenu[index].price * this.smenu[index].qtty;
              this.smenu[index].note += save.note;
              const findopt = this.find[0].optionSelec.filter((element) => {
                return element.id === save.optionSelec[0].id;
              });

              let idxopt = this.smenu[index].optionSelec.indexOf(findopt[0]);
              if (findopt) {
                this.smenu[index].optionSelec[idxopt].qtty +=
                  save.optionSelec[0].qtty;
              }
            } else if (save.optionSelec.length === 2) {
              this.smenu[index].qtty = this.smenu[index].qtty + save.qtty;
              this.smenu[index].priceTotal =
                this.smenu[index].price * this.smenu[index].qtty;
              this.smenu[index].note += save.note;
              const findopt1 = this.find[0].optionSelec.filter((element) => {
                return element.id === save.optionSelec[0].id;
              });

              const findopt2 = this.find[0].optionSelec.filter((element) => {
                return element.id === save.optionSelec[1].id;
              });

              let idxopt1 = this.smenu[index].optionSelec.indexOf(findopt1[0]);
              let idxopt2 = this.smenu[index].optionSelec.indexOf(findopt2[0]);
              if (findopt1.length > 0) {
                this.smenu[index].optionSelec[idxopt1].qtty +=
                  save.optionSelec[0].qtty;
              } else {
                this.smenu[index].optionSelec.push(save.optionSelec[0]);
              }

              if (findopt2.length > 0) {
                this.smenu[index].optionSelec[idxopt2].qtty +=
                  save.optionSelec[1].qtty;
              } else {
                this.smenu[index].optionSelec.push(save.optionSelec[1]);
              }
            }

            this.closeModal();
            this.countKasir();
          } else {
            this.smenu.push(save);
            this.closeModal();
            this.countKasir();
          }
        }
      }
    },

    async submitDetail() {
      try {
        const item = [];
        this.smenu.forEach((element) => {
          element['node'] = true;
          element['main_food'] = true;
          item.push(element);
          if (element.optionSelec.length > 0) {
            element.optionSelec.forEach((data) => {
              data['main_food'] = false;
              data['node'] = false;
              data['tax'] = 0;
              data['note'] = '';
              item.push(data);
            });
          }
        });

        const details = {
          idreserv: this.$route.params.id,
          kdcab: 'J07',
          userid: this.$store.getters.GET_AUTH_INFO.id,
          items: item,
        };

        await axios
          .post('/reservasi-detail', details, {
            headers: {
              Authorization: this.$store.getters.GET_AUTH_TOKEN,
            },
          })
          .then(() => {
            history.go(-1);
          });
      } catch (error) {
        console.log(error);
      }
    },

    /*Menampilkan detail item smenu yang ada dilist menu kasir*/
    detailItem(idSmenu) {
      this.detailSmenu = {};
      this.smenu.forEach((el) => {
        if (el.id === idSmenu) {
          this.detailSmenu = el;
        }
      });
      this.modalDetailItem = true;
    },

    /*Meraplace gambar jpg to png untuk di tampilkan di item/option*/
    getSauceImg(filename) {
      const name = filename.replace('.JPG', '.png');
      return 'https://tamani-pos.online/assets/images/options/tamani/' + name;
    },

    /*Untuk medelete menu yang ada di list menu kasir*/
    deleteItem(item) {
      this.smenu.splice(this.smenu.indexOf(item), 1);
    },

    /*Menghitung jumlah dan harga item smenu yang ada d list menu kasir*/
    countKasir() {
      this.count = {
        totalQtty: 0,
        subTotal: 0,
        tax: 0,
        grandTotal: 0,
      };

      if (this.smenu.length > 0) {
        this.smenu.forEach((el) => {
          this.count.totalQtty = this.count.totalQtty + el.qtty;
          this.count.subTotal = this.count.subTotal + el.priceTotal;
        });
        this.count.tax = this.count.subTotal * 0.1;
        this.count.grandTotal = this.count.subTotal + this.count.tax;
      }
    },

    /*Methode untuk close modal dan meghapus data yang ada di form menudetail*/
    closeModal() {
      this.namaItem = null;
      this.idItem = null;
      this.namaItem1 = null;
      this.idItem1 = null;
      this.modalMenuItem = false;
      this.modalDetailItem = false;
      this.activeMatang = null;
      this.notes = '';
      this.qtyharga = 1;
      this.matangSelec = null;
      this.modalCancel = false;
      this.idMatang = null;
    },

    /*untuk mengatur tambah quantity*/
    tambah() {
      this.qtyharga += 1;
    },

    /*untuk mengatur kurang quantity*/
    kurang() {
      if (this.qtyharga > 1) {
        this.qtyharga -= 1;
      }
    },

    /*Membuat Format Decimal*/
    formatDecimal(value) {
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
  },
};
</script>

<style scoped>
.list-group-item.activecat {
  background-color: lightblue;
}
.btn.activeitem {
  background-color: lightblue;
}

.foods {
  height: 250px;
  margin: 5px;
  font-size: 10pt;
  cursor: pointer;
}

.foods:hover {
  background: var(--light-theme);
}
.modal {
  display: none;
}
.show {
  display: block;
}

.zoom {
  transition: all 0.5s ease;
}
.zoom:hover {
  scale: 1.1;
}
</style>
