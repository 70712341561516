<template>
  <!-- Modal Belum Cetak-->
  <div
    class="modal fade"
    id="ModalCetak"
    data-bs-backdrop="false"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="ModalCetakLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h1
            class="modal-title fs-5"
            id="ModalCetakLabel">
            Print Reservation
          </h1>
          <button
            type="button"
            class="btn-close"
            @click="closeModal"
            data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>

        <!-- Boddy Sudah Cetak -->
        <div
          class="modal-body"
          v-if="reserv.cetak === 1">
          <h6 clss="fw-bold">
            Reservation has been printed, Do you want to reprint it?
          </h6>
          <a
            href="#"
            class="btn btn-success w-100"
            ><i class="ri-printer-fill"></i> Reprint</a
          >
        </div>

        <!-- Boddy Belum Cetak -->
        <div
          class="modal-body"
          v-if="reserv.cetak === 0">
          <h6>Down Payment Methode :</h6>
          <div class="row">
            <div class="col-lg-4">
              <a
                href="#"
                class="btn btn-success w-100"
                @click="activeTunais"
                ><i class="ri-money-dollar-box-line"></i> Cash</a
              >
            </div>
            <div class="col-lg-4">
              <a
                href="#"
                class="btn btn-success w-100"
                @click="activeCards"
                ><i class="ri-bank-card-2-line"></i> Card</a
              >
            </div>
            <div class="col-lg-4">
              <a
                href="#"
                class="btn btn-success w-100"
                @click="activeTransfer"
                ><i class="ri-currency-fill"></i> Midtrans</a
              >
            </div>
          </div>

          <!-- form active transfer -->
          <div
            class="mt-4"
            v-if="activeTunai">
            <hr />
            <h1
              class="modal-title fs-5 mb-3 mt-3"
              id="ModalCreateLabel">
              Form Payment Cash
            </h1>
            <div class="mb-3">
              <input
                type="text"
                id="jenisbayar"
                value="T"
                hidden />
              <label
                for="totalDP"
                class="form-label"
                >Total Downpayment</label
              >
              <input
                type="text"
                class="form-control"
                id="totalDP"
                name="totalDP"
                :disabled="reserv.downpay !== null"
                :value="formatDecimal(reserv.downpay)" />
            </div>
            <button
              type="submit"
              class="btn btn-success w-100 justify-content-center"
              @click="submitPayment()">
              Submit
            </button>
          </div>

          <!-- form active transfer -->
          <div
            class="mt-4"
            v-if="activeTf">
            <hr />
            <h1
              class="modal-title fs-5 mb-3 mt-3"
              id="ModalCreateLabel">
              Payment Gateway
            </h1>
            <!-- <div class="mb-3">
              <input
                type="text"
                id="jenisbayar"
                value="S"
                hidden />
              <label
                for="nocard"
                class="form-label"
                >Account Number
                <span class="text-danger">*{{ error.nocard }}</span></label
              >
              <input
                type="text"
                class="form-control"
                id="nocard"
                name="nocard"
                v-model="nocard" />
            </div>
            <div class="mb-3">
              <label
                for="nmcard"
                class="form-label"
                >Account Name
                <span class="text-danger">*{{ error.nmcard }}</span></label
              >
              <input
                type="text"
                class="form-control"
                id="nmcard"
                name="nmcard"
                v-model="nmcard" />
            </div> -->
            <button
              type="submit"
              class="btn btn-success w-100 justify-content-center"
              @click="submitPayment()">
              Submit
            </button>
          </div>

          <!-- form active card -->
          <div
            class="mt-4"
            v-if="activeCard">
            <hr />
            <h1
              class="modal-title fs-5 mb-3 mt-3"
              id="ModalCreateLabel">
              Form Payment Card
            </h1>
            <div class="mb-3">
              <input
                type="text"
                id="jenisbayar"
                value="C"
                hidden />
              <label
                for="totalDP"
                class="form-label"
                >Total Downpayment</label
              >
              <input
                type="text"
                class="form-control"
                id="totalDP"
                name="totalDP"
                :disabled="reserv.downpay !== null"
                :value="formatDecimal(reserv.downpay)" />
            </div>
            <div class="mb-3">
              <label
                for="nocard"
                class="form-label"
                >Card Number
                <span class="text-danger">*{{ error.nocard }}</span></label
              >
              <input
                type="text"
                class="form-control"
                id="nocard"
                name="nocard"
                v-model="nocard" />
            </div>
            <div class="mb-3">
              <label
                for="nmcard"
                class="form-label"
                >Card Holder
                <span class="text-danger">*{{ error.nmcard }}</span></label
              >
              <input
                type="text"
                class="form-control"
                id="nmcard"
                name="nmcard"
                v-model="nmcard" />
            </div>
            <div class="mb-3">
              <label
                for="kdmerchant"
                class="form-label"
                >EDC Code
                <span class="text-danger">*{{ error.kdmerchant }}</span></label
              >
              <select
                id="kdmerchant"
                class="form-select">
                <option value="ATM">ATM</option>
                <option value="BCA">BCA</option>
                <option value="BNI">BNI</option>
                <option value="BRI">BRI</option>
                <option value="DNA">DNA</option>
                <option value="GBZ">GBZ</option>
                <option value="GPY">GPY</option>
                <option value="GRF">GRF</option>
                <option value="LPB">LPB</option>
                <option value="MDR">MDR</option>
                <option value="OVO">OVO</option>
                <option value="SPY">SPY</option>
                <option value="TVL">TVL</option>
              </select>
            </div>
            <div class="mb-3">
              <label
                for="noapprov"
                class="form-label"
                >Approval Number
                <span class="text-danger">*{{ error.noapprov }}</span></label
              >
              <input
                type="text"
                class="form-control"
                id="noapprov"
                name="noapprov"
                v-model="noapprov" />
            </div>
            <button
              type="submit"
              class="btn btn-success w-100 justify-content-center"
              @click="submitPayment()">
              Submit
            </button>
          </div>

          <div
            v-if="editStatusMessage === 200"
            class="text-success text-center fs-6 mt-3">
            {{ editMessage }}
          </div>
          <div
            v-if="editStatusMessage === 400"
            class="text-danger text-center fs-6 mt-3">
            {{ editMessage }}
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            @click="closeModal"
            class="btn btn-secondary"
            data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ModalCetak',
  props: ['reserv'],
  data() {
    return {
      errorCnt: 0,
      activeTf: false,
      activeCard: false,
      activeTunai: false,
      cetak: null,
      jenisbyr: null,
      nocard: null,
      nmcard: null,
      kdmerchant: null,
      noapprov: null,
      error: {
        nocard: null,
        nmcard: null,
        kdmerchant: null,
        noapprov: null,
      },
      editMessage: '',
      editStatusMessage: null,
    };
  },

  mounted() {},

  methods: {
    activeTransfer() {
      this.activeCard = false;
      this.activeTf = true;
      this.activeTunai = false;
      this.jenisbyr = 'M';
      this.error.nocard = null;
      this.error.nmcard = null;
      this.error.kdmerchant = null;
      this.error.noapprov = null;
    },
    activeCards() {
      this.activeCard = true;
      this.activeTf = false;
      this.activeTunai = false;
      this.jenisbyr = 'C';
      this.error.nocard = null;
      this.error.nmcard = null;
      this.error.kdmerchant = null;
      this.error.noapprov = null;
    },
    activeTunais() {
      this.activeTunai = true;
      this.activeTf = false;
      this.activeCard = false;
      this.jenisbyr = 'T';
      this.error.nocard = null;
      this.error.nmcard = null;
      this.error.kdmerchant = null;
      this.error.noapprov = null;
    },

    async submitPayment() {
      try {
        this.error.nocard = null;
        this.error.nmcard = null;
        this.error.kdmerchant = null;
        this.error.noapprov = null;

        if (this.jenisbyr === 'C') {
          if (!this.nocard) {
            this.error.nocard = 'Is Required';
            this.errorCnt += 1;
          }
          if (!this.nmcard) {
            this.error.nmcard = 'Is Required';
            this.errorCnt += 1;
          }
          if (!document.getElementById('kdmerchant').value) {
            this.error.kdmerchant = 'Is Required';
            this.errorCnt += 1;
          }
          if (!this.noapprov) {
            this.error.noapprov = 'Is Required';
            this.errorCnt += 1;
          }

          if (this.errorCnt > 0) {
            throw new Error('');
          }

          const payment = {
            idreserv: this.reserv.id,
            userid: this.$store.getters.GET_AUTH_INFO.id,
            cetak: 1,
            payment: this.jenisbyr,
            nocard: this.nocard,
            nmcard: this.nmcard,
            kdmerchant: document.getElementById('kdmerchant').value,
            noapprov: this.noapprov,
          };
          const { data } = await axios.post('/reservasi-payment', payment, {
            headers: {
              Authorization: this.$store.getters.GET_AUTH_TOKEN,
            },
          });
        }
        if (this.jenisbyr === 'M') {
          // if (!this.nocard) {
          //   this.error.nocard = 'Is Required';
          //   this.errorCnt += 1;
          // }
          // if (!this.nmcard) {
          //   this.error.nmcard = 'Is Required';
          //   this.errorCnt += 1;
          // }

          // if (this.errorCnt > 0) {
          //   throw new Error('');
          // }

          const payment = {
            idreserv: this.reserv.id,
            userid: this.$store.getters.GET_AUTH_INFO.id,
            cetak: 1,
            payment: this.jenisbyr,
          };
          const { data } = await axios.post('/reservasi-payment', payment, {
            headers: {
              Authorization: this.$store.getters.GET_AUTH_TOKEN,
            },
          });
        }
        if (this.jenisbyr === 'T') {
          const payment = {
            idreserv: this.reserv.id,
            userid: this.$store.getters.GET_AUTH_INFO.id,
            cetak: 1,
            payment: this.jenisbyr,
          };
          const { data } = await axios.post('/reservasi-payment', payment, {
            headers: {
              Authorization: this.$store.getters.GET_AUTH_TOKEN,
            },
          });
        }
        this.editMessage = 'Payment successfully!';
        this.editStatusMessage = 200;

        setTimeout(() => {
          window.location.reload();
          this.editMessage = '';
          this.editStatusMessage = null;
        }, 1000);
      } catch (error) {
        console.log(error);
        this.editMessage = 'Payment failed to print!!!';
        this.editStatusMessage = 400;
      }
    },

    closeModal() {
      this.activeTf = false;
      this.activeCard = false;
      this.activeTunai = false;
      this.cetak = null;
      this.jenisbyr = null;
      this.nocard = null;
      this.nmcard = null;
      this.kdmerchant = null;
      this.noapprov = null;
      this.editMessage = '';
      this.editStatusMessage = null;
    },

    /*Membuat Format Decimal*/
    formatDecimal(value) {
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
  },
};
</script>
