<template>
  <!-- DataTales Rooms -->
  <div class="card shadow mb-4">
    <div class="card-header py-3">
      <h6 class="m-0 font-weight-bold text-success">Table Users</h6>
    </div>
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-lg-8">
          <!-- Button trigger modal -->
          <button
            type="button"
            class="btn btn-success"
            data-bs-toggle="modal"
            data-bs-target="#ModalCreate">
            Create New User
          </button>
        </div>
        <div class="col-lg-4">
          <input
            v-model="params.search"
            class="form-control me-2 is-valid"
            type="search"
            placeholder="Search"
            aria-label="Search" />
        </div>
      </div>
      <div class="table-responsive">
        <vue3-datatable
          :search="params.search"
          :rows="rows"
          :columns="cols"
          :totalRows="total_rows"
          :sortable="true"
          :sortColumn="params.sort_column"
          :sortDirection="params.sort_direction">
          <template #edit="data">
            <div class="text-center">
              <a
                class="btn btn-primary text-center"
                data-bs-toggle="modal"
                data-bs-target="#ModalEdit"
                @click="showEdit(data.value)">
                <i class="ri-edit-box-fill fs-6"></i>
              </a>
            </div>
          </template>
          <template #delete="data">
            <div class="text-center">
              <a
                class="btn btn-danger text-center"
                data-bs-toggle="modal"
                data-bs-target="#ModalDelete"
                @click="showEdit(data.value)">
                <i class="ri-calendar-close-fill"></i>
              </a>
            </div>
          </template>
        </vue3-datatable>
      </div>
    </div>
  </div>

  <!-- Modal Created-->
  <div
    class="modal fade"
    id="ModalCreate"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="ModalCreateLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div
        class="modal-content"
        style="
          height: 90vh;
          max-height: 600px;
          overflow-y: scroll;
          overflow-x: hidden;
        ">
        <div class="modal-header">
          <h1
            class="modal-title fs-5"
            id="ModalCreateLabel">
            Form Create User
          </h1>
          <button
            type="button"
            @click="closeModal"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="mb-3">
              <label
                for="nik"
                class="form-label"
                >NIK User</label
              >
              <input
                type="number"
                class="form-control"
                id="nik"
                name="nik"
                v-model="nik" />
              <span class="text-danger">{{ isError.nik }}</span>
            </div>
            <div class="mb-3">
              <label
                for="userId"
                class="form-label"
                >User Id</label
              >
              <input
                type="text"
                class="form-control"
                id="userId"
                name="userId"
                v-model="userId" />
              <span class="text-danger">{{ isError.userId }}</span>
            </div>
            <div class="mb-3">
              <label
                for="name"
                class="form-label"
                >Name</label
              >
              <input
                type="text"
                class="form-control"
                id="name"
                name="name"
                v-model="name" />
              <span class="text-danger">{{ isError.name }}</span>
            </div>
            <div class="mb-3">
              <label
                for="email"
                class="form-label"
                >Email User</label
              >
              <input
                type="email"
                class="form-control"
                id="email"
                name="email"
                v-model="email" />
              <span class="text-danger">{{ isError.email }}</span>
            </div>
            <div class="mb-3">
              <label
                for="lok_kd"
                class="form-label"
                >Select Branch</label
              >
              <select
                id="lok_kd"
                class="form-select"
                v-model="lok_kd">
                <option
                  v-for="cab in branch"
                  :key="cab.id"
                  :value="cab.kdcab">
                  {{ cab.kdcab + ' ' + cab.company }}
                </option>
              </select>
              <span class="text-danger">{{ isError.lok_kd }}</span>
            </div>
            <div class="mb-3">
              <label
                for="password"
                class="form-label"
                >Passowrd</label
              >
              <input
                type="password"
                class="form-control"
                id="password"
                name="password"
                v-model="password" />
              <span class="text-danger">{{ isError.password }}</span>
            </div>

            <button
              type="submit"
              class="btn btn-success w-100 justify-content-center"
              @click="userCreate">
              Submit
            </button>
            <div
              v-if="statusMessage === 200"
              class="text-success text-center fs-6 mt-3">
              {{ createMessage }}
            </div>
            <div
              v-if="statusMessage === 400"
              class="text-danger text-center fs-6 mt-3">
              {{ createMessage }}
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            @click="closeModal"
            class="btn btn-secondary"
            data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Edited-->
  <div
    class="modal fade"
    id="ModalEdit"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="ModalEditLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div
        class="modal-content"
        style="
          height: 90vh;
          max-height: 600px;
          overflow-y: scroll;
          overflow-x: hidden;
        ">
        <div class="modal-header">
          <h1
            class="modal-title fs-5"
            id="ModalEditLabel">
            Form Edit User
          </h1>
          <button
            type="button"
            @click="closeModal"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="mb-3">
              <label
                for="nik"
                class="form-label"
                >NIK User</label
              >
              <input
                type="text"
                class="form-control"
                id="nik"
                name="nik"
                disabled
                readonly
                :value="pilihUser.nik" />
            </div>
            <div class="mb-3">
              <label
                for="userIdE"
                class="form-label"
                >User Id</label
              >
              <input
                type="text"
                class="form-control"
                id="userIdE"
                name="userIdE"
                disabled
                readonly
                :value="pilihUser.userid" />
            </div>
            <div class="mb-3">
              <label
                for="nameE"
                class="form-label"
                >Name</label
              >
              <input
                type="text"
                class="form-control"
                id="nameE"
                name="nameE"
                disabled
                readonly
                :value="pilihUser.name" />
            </div>
            <div class="mb-3">
              <label
                for="lok_kd"
                class="form-label"
                >Branch</label
              >
              <input
                type="text"
                class="form-control"
                id="lok_kd"
                name="lok_kd"
                disabled
                readonly
                :value="pilihUser.lok_kd" />
              <span class="text-danger">{{ isError.lok_kd }}</span>
            </div>
            <div class="mb-3">
              <label
                for="roleId"
                class="form-label"
                >User Role</label
              >
              <select
                class="form-select"
                id="roleId"
                name="roleId"
                :value="pilihUser.roleId">
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </select>
              <span class="text-danger">{{ isError.roleId }}</span>
            </div>
            <button
              type="submit"
              class="btn btn-success w-100 justify-content-center"
              @click="userEdit(pilihUser.id, pilihUser.roleId)">
              Submit
            </button>
            <div
              v-if="statusMessage === 200"
              class="text-success text-center fs-6 mt-3">
              {{ createMessage }}
            </div>
            <div
              v-if="statusMessage === 400"
              class="text-danger text-center fs-6 mt-3">
              {{ createMessage }}
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            @click="closeModal"
            class="btn btn-secondary"
            data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Delete Message -->
  <div
    class="modal fade"
    id="ModalDelete"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="ModalDeleteLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title fw-bold">
            Are you sure you want to delete {{ pilihUser.name }} ?
          </h5>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-danger"
            style="width: 100px"
            @click="userDelete(pilihUser.id)">
            Yes
          </button>
          <button
            type="button"
            class="btn btn-primary"
            style="width: 100px"
            data-bs-dismiss="modal"
            @click="closeModal()">
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'TableUser',

  data() {
    return {
      users: [],
      branch: [],
      nik: null,
      userId: null,
      name: null,
      email: null,
      lok_kd: null,
      password: null,
      isError: {
        nik: null,
        userId: null,
        name: null,
        email: null,
        lok_kd: null,
        password: null,
        roleId: null,
      },
      createMessage: '',
      statusMessage: null,
      pilihUser: {},
      errorcnt: 0,
    };
  },

  created() {
    this.getUsers();
    this.getBranch();
  },

  methods: {
    async getUsers() {
      try {
        const { data } = await axios.get('/user', {
          headers: {
            Authorization: this.$store.getters.GET_AUTH_TOKEN,
            User: this.$store.getters.GET_AUTH_INFO.id,
          },
        });

        this.users = data.data;
      } catch (error) {
        console.log('404, Get user failed');
        if (error.response.status == 401) {
          window.location.href = '/reservasi';
        }
      }
    },
    async getBranch() {
      try {
        const { data } = await axios.get('/branch', {
          headers: {
            Authorization: this.$store.getters.GET_AUTH_TOKEN,
          },
        });

        this.branch = data.data;
      } catch (error) {
        console.log('401, error');
      }
    },

    async userCreate() {
      try {
        this.isError.nik = null;
        this.isError.userId = null;
        this.isError.name = null;
        this.isError.email = null;
        this.isError.lok_kd = null;
        this.isError.password = null;
        if (!this.nik) this.isError.nik = 'Nik user is required';
        if (!this.userId) this.isError.userId = 'User id is required';
        if (!this.name) this.isError.name = 'Name is required';
        if (!this.email) this.isError.email = 'Email is required';
        if (!this.lok_kd) this.isError.lok_kd = 'Branch is required';
        if (!this.password) this.isError.password = 'Password is required';

        this.errorcnt = 0;
        this.users.forEach((el) => {
          if (el.nik === String(this.nik)) {
            this.isError.nik = 'Nik is ready';
            this.errorcnt += 1;
          }
          if (el.userid === this.userId) {
            this.isError.userId = 'User Id is ready';
            this.errorcnt += 1;
          }
          if (el.email === this.email) {
            this.isError.email = 'Email is ready';
            this.errorcnt += 1;
          }
        });

        if (this.errorcnt > 0) {
          throw new Error('');
        }

        const createUser = {
          nik: String(this.nik),
          userid: this.userId,
          name: this.name,
          email: this.email,
          lok_kd: this.lok_kd,
          password: this.password,
        };
        await axios.post('/user', createUser, {
          headers: {
            Authorization: this.$store.getters.GET_AUTH_TOKEN,
          },
        });
        this.createMessage = 'Created user successfully!';
        this.statusMessage = 200;
        setTimeout(() => {
          window.location.reload();
          this.createMessage = '';
          this.statusMessage = null;
        }, 1000);
      } catch (error) {
        this.createMessage = 'Created user failed!!!';
        this.statusMessage = 400;
        console.log('400, Created failed');
      }
    },

    showEdit(userPilih) {
      this.pilihUser = {};
      const selected = this.users.filter((data) => {
        return data.id === userPilih.id;
      });
      this.pilihUser = selected[0];
    },

    async userEdit(id, role) {
      try {
        this.errorcnt = 0;
        this.isError.roleId = null;
        if (!document.getElementById('roleId').value) {
          this.isError.roleId = 'Role Id is required';
          this.errorcnt += 1;
        }
        if (this.errorcnt > 0) {
          throw new Error('');
        }

        if (parseInt(document.getElementById('roleId').value) === role) {
          this.createMessage = 'No data has been changed!';
          this.statusMessage = 200;
          console.log('200, Edited success');
          setTimeout(() => {
            window.location.reload();
            this.createMessage = '';
            this.statusMessage = null;
          }, 1000);
        } else {
          const editUser = {
            id: id,
            roleId: parseInt(document.getElementById('roleId').value),
          };

          await axios.put('/user', editUser, {
            headers: {
              Authorization: this.$store.getters.GET_AUTH_TOKEN,
            },
          });

          this.createMessage = 'Edited user successfully!';
          this.statusMessage = 200;
          console.log('200, Edited success');
          setTimeout(() => {
            window.location.reload();
            this.createMessage = '';
            this.statusMessage = null;
          }, 1000);
        }
      } catch (error) {
        this.createMessage = 'Edited user failed!!!';
        this.statusMessage = 400;
        console.log('400, Edited failed!!!');
      }
    },

    async userDelete(id) {
      try {
        await axios.delete('/user', {
          headers: {
            Authorization: this.$store.getters.GET_AUTH_TOKEN,
          },
          data: {
            userid: id,
          },
        });

        window.location.reload();
      } catch (error) {
        console.log('400, Error Delete!!!');
      }
    },

    async closeModal() {
      this.createMessage = '';
      this.statusMessage = null;
      this.isError.nik = null;
      this.isError.userId = null;
      this.isError.name = null;
      this.isError.email = null;
      this.isError.lok_kd = null;
      this.isError.password = null;
      this.isError.roleId = null;
    },
  },
};
</script>

<script setup>
import { ref, onMounted, reactive } from 'vue';
import Vue3Datatable from '@bhplugin/vue3-datatable';
import '@bhplugin/vue3-datatable/dist/style.css';
import store from '../store';

onMounted(() => {
  getUser();
});

const params = reactive({
  search: '',
  sort_column: 'index',
  sort_direction: 'asc',
});

const total_rows = ref(0);

const rows = ref(null);

const cols =
  ref([
    { field: 'index', title: 'No', width: '20px' },
    { field: 'nik', title: 'NIK' },
    { field: 'userid', title: 'User Id' },
    { field: 'name', title: 'Name' },
    { field: 'email', title: 'Email' },
    { field: 'lok_kd', title: 'Branch' },
    { field: 'roleId', title: 'User Role' },
    { field: 'edit', title: 'Edit', width: '20px' },
    { field: 'delete', title: 'Delete', width: '20px' },
  ]) || [];

const getUser = async () => {
  try {
    const { data } = await axios.get('/user', {
      headers: {
        Authorization: store.getters.GET_AUTH_TOKEN,
        User: store.getters.GET_AUTH_INFO.id,
      },
    });
    const response = await data.data;
    const users = [];
    response.forEach((el, index) => {
      const responses = {
        id: el.id,
        index: index + 1,
        userid: el.userid,
        name: el.name,
        email: el.email,
        lok_kd: el.lok_kd,
        compkd: el.compkd,
        dept_kd: el.dept_kd,
        divisi_kd: el.divisi_kd,
        emailConfirmed: el.emailConfirmed,
        image: el.image,
        isActive: el.isActive,
        nik: el.nik,
        password: el.password,
        roleId: el.roleId,
        subdiv_kd: el.subdiv_kd,
        createdAt: el.createdAt,
        updateAt: el.updateAt,
      };
      users.push(responses);
    });
    rows.value = users;
    total_rows.value = response.total;
  } catch (error) {
    console.log('400, Error data Table');
    if (error.response.status == 401) {
      window.location.href = '/reservasi';
    }
  }
};
</script>

<style scoped>
.form-control.is-valid {
  background-image: none !important;
}
</style>
