import { createRouter, createWebHistory } from 'vue-router';
import LoginViews from '../views/Login.vue';
import ReservasiViews from '../views/Reservasi.vue';
import ValidasiViews from '../views/Validasi.vue';
import RoomViews from '../views/Room.vue';
import UserViews from '../views/User.vue';
import DetailViews from '../views/Detail.vue';
import MenuViews from '../views/Menu.vue';
import CalendarViews from '../views/Calendar.vue';
import ReservationViews from '../views/ReservasiUser.vue';
import ReservasiSuccessView from '@/views/ReservasiSuccess.vue';
import ReservasiFailed from '@/views/ReservasiFailed.vue';
import ReservasiPaymentView from '@/views/ReserVasiPayment.vue';
import store from '@/store';

const routes = [
  {
    path: '/admin-login',
    name: 'home',
    component: LoginViews,
    meta: { guest: true },
  },
  {
    path: '/reservasi',
    name: 'reservasi',
    component: ReservasiViews,
    meta: { login: true },
  },
  {
    path: '/validasi',
    name: 'validasi',
    component: ValidasiViews,
    meta: { finance: true },
  },
  { path: '/room', name: 'room', component: RoomViews, meta: { spv: true } },
  { path: '/user', name: 'user', component: UserViews, meta: { admin: true } },
  {
    path: '/calendar',
    name: 'calendar',
    component: CalendarViews,
    meta: { login: true },
  },
  {
    path: '/detail/:id',
    name: 'detail',
    component: DetailViews,
    meta: { login: true },
  },
  {
    path: '/detail/menu/:id',
    name: 'menu',
    component: MenuViews,
    meta: { login: true },
  },
  { path: '/', name: 'reservation', component: ReservationViews },
  {
    path: '/reservation-success',
    name: 'reservation-success',
    component: ReservasiSuccessView,
  },
  {
    path: '/reservation-failed',
    name: 'reservation-failed',
    component: ReservasiFailed,
  },
  {
    path: '/reservation-payment/:id',
    name: 'reservation-payment',
    component: ReservasiPaymentView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.login)) {
    if (!store.getters.GET_AUTH_TOKEN) {
      next({
        name: 'reservation',
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.GET_AUTH_TOKEN) {
      next({
        name: 'reservasi',
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.admin)) {
    if (
      store.getters.GET_AUTH_TOKEN &&
      store.getters.GET_AUTH_INFO.role !== 1
    ) {
      next({
        name: 'reservasi',
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.spv)) {
    if (
      (store.getters.GET_AUTH_TOKEN &&
        store.getters.GET_AUTH_INFO.role === 1) ||
      store.getters.GET_AUTH_INFO.role === 2
    ) {
      next();
    } else {
      next({
        name: 'reservasi',
      });
    }
  } else if (to.matched.some((record) => record.meta.finance)) {
    if (
      (store.getters.GET_AUTH_TOKEN &&
        store.getters.GET_AUTH_INFO.role === 1) ||
      store.getters.GET_AUTH_INFO.role === 4
    ) {
      next();
    } else {
      next({
        name: 'reservasi',
      });
    }
  } else {
    next();
  }
});

export default router;
