<template>
  <!-- DataTales Example -->
  <div class="card shadow mb-4">
    <div class="card-header py-3">
      <h6 class="m-0 font-weight-bold text-success">Table Validasi</h6>
    </div>
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-lg-8"></div>
        <div class="col-lg-4">
          <input
            v-model="params.search"
            class="form-control me-2 is-valid"
            type="search"
            placeholder="Search"
            aria-label="Search" />
        </div>
      </div>
      <div class="table-responsive">
        <vue3-datatable
          :search="params.search"
          :rows="rows"
          :columns="cols"
          :totalRows="total_rows"
          :sortable="true"
          :sortColumn="params.sort_column"
          :sortDirection="params.sort_direction">
          <template #uploadTf="data">
            <div>
              <a
                class="btn btn-success text-center"
                data-bs-toggle="modal"
                data-bs-target="#modalPayment"
                @click="getPaymentId(data.value)">
                <i class="ri-currency-fill"></i>
              </a>
            </div>
          </template>
        </vue3-datatable>
      </div>
    </div>
  </div>

  <ModalPayment :itemid="idPayment"></ModalPayment>
</template>

<script>
import ModalPayment from './ModalPayment.vue';
import axios from 'axios';

export default {
  name: 'TableValidasi',
  components: {
    ModalPayment,
  },
  data() {
    return {
      idPayment: null,
      reserv: [],
    };
  },
  mounted() {
    this.getReservasi();
  },
  methods: {
    getPaymentId(reservasi) {
      this.idPayment = reservasi.id;
    },
    async getReservasi() {
      try {
        const { data } = await axios.get('/reservasi', {
          headers: {
            Authorization: this.$store.getters.GET_AUTH_TOKEN,
            User: this.$store.getters.GET_AUTH_INFO.id,
            Filter: 'Upcoming',
          },
        });

        this.reserv = data.data;
      } catch (error) {
        console.log(error);
        if(error.response.status == 401){
            store.dispatch("LOGOUT")
            .then(() => {
                window.location.href = "/admin-login"
            })
            .catch(() => {
                window.location.href = "/admin-login"
            })
        }
      }
    },
  },
};
</script>

<script setup>
import { ref, onMounted, reactive } from 'vue';
import Vue3Datatable from '@bhplugin/vue3-datatable';
import '@bhplugin/vue3-datatable/dist/style.css';
import store from '../store';

onMounted(() => {
  getRsv();
});

const params = reactive({
  search: '',
  sort_column: 'noreserv',
  sort_direction: 'asc',
});

const total_rows = ref(0);

const rows = ref(null);

const cols =
  ref([
    { field: 'noreserv', title: 'No Resv', width: '20px' },
    { field: 'atasnama', title: 'On Behalf' },
    { field: 'tglinput', title: 'Input Date', type: 'date', width: '50px' },
    {
      field: 'tglreserv',
      title: 'Reservation Date',
      type: 'date',
      width: '50px',
    },
    { field: 'status', title: 'Status', width: '50px' },
    { field: 'uploadTf', title: 'Upload Proof', width: '50px' },
  ]) || [];

const getRsv = async () => {
  try {
    const { data } = await axios.get('/reservasi', {
      headers: {
        Authorization: store.getters.GET_AUTH_TOKEN,
        User: store.getters.GET_AUTH_INFO.id,
        Filter: 'Upcoming',
      },
    });
    const response = await data.data;
    const resv = [];
    response.forEach((el) => {
      if (el.status === 'Processing' && el.jenisbyr === 'S') {
        const responses = {
          id: el.id,
          noreserv: el.noreserv,
          atasnama: el.atasnama,
          tglinput: new Date(el.tglinput).toLocaleDateString(),
          tglreserv: new Date(el.tglreserv).toLocaleDateString(),
          status: el.status,
          cetak: el.cetak,
        };
        resv.push(responses);
      }
    });
    rows.value = resv;
    total_rows.value = response.total;
  } catch (error) {
    console.log(error);
  }
};
</script>

<style scoped>
.form-control.is-valid {
  background-image: none !important;
}
</style>
