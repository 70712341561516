<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="ModalEdit"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="ModalEditLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div
        class="modal-content"
        style="
          height: 90vh;
          overflow-y: scroll;
          overflow-x: hidden;
          z-index: 1000;
          box-shadow: 9px 9px 6px -3px rgba(0, 0, 0, 0.1);
        ">
        <div class="modal-header">
          <h1
            class="modal-title fs-5"
            id="ModalEditLabel">
            Form Edit Reservation
          </h1>
          <button
            type="button"
            @click="closeModal"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <fieldset disabled>
              <div class="row mb-3">
                <div class="col-lg-6">
                  <label
                    for="tgl-input"
                    class="form-label"
                    >Input Date</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="tgl-input"
                    name="tgl-input"
                    :value="filterDate(reserv.tglinput)" />
                </div>

                <div class="col-lg-6">
                  <label
                    for="input-oleh"
                    class="form-label"
                    >Input By.</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="input-oleh"
                    name="input-oleh"
                    :value="reserv.userid"
                    :disabled="reserv.status === 'Completed'" />
                </div>
              </div>
            </fieldset>

            <div class="row mb-3">
              <div class="col-lg-4">
                <label
                  for="tglresv"
                  class="form-label"
                  >Reservation Date</label
                >
                <input
                  type="date"
                  class="form-control"
                  id="tglresv"
                  name="tglresv"
                  :value="filterDateInput(reserv.tglreserv)"
                  :disabled="reserv.status === 'Completed'" />
                <span class="text-danger">{{ error.tglreserv }}</span>
              </div>
              <div class="col-lg-4">
                <label
                  for="jamresv"
                  class="form-label"
                  >Start Time</label
                >
                <input
                  type="time"
                  class="form-control"
                  id="jamresv"
                  name="jamresv"
                  :value="reserv.jamreserv"
                  :disabled="reserv.status === 'Completed'" />
                <span class="text-danger">{{ error.jamreserv }}</span>
              </div>
              <div class="col-lg-4">
                <label
                  for="sdjam"
                  class="form-label"
                  >End Time</label
                >
                <input
                  type="time"
                  class="form-control"
                  id="sdjam"
                  name="sdjam"
                  :value="reserv.sdjamrsv"
                  :disabled="reserv.status === 'Completed'" />
                <span class="text-danger">{{ error.sdjam }}</span>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-lg-6">
                <label
                  for="atasnama"
                  class="form-label"
                  >On Behalf</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="atasnama"
                  name="atasnama"
                  :value="reserv.atasnama"
                  :disabled="
                    reserv.status === 'Paid' || reserv.status === 'Completed'
                  " />
                <span class="text-danger">{{ error.atasnama }}</span>
              </div>
              <div class="col-lg-6">
                <label
                  for="notelp"
                  class="form-label"
                  >Phone Number</label
                >
                <input
                  type="number"
                  class="form-control"
                  id="notelp"
                  name="notelp"
                  :value="reserv.notelp"
                  :disabled="
                    reserv.status === 'Paid' || reserv.status === 'Completed'
                  " />
                <span class="text-danger">{{ error.notelp }}</span>
              </div>
            </div>

            <div class="mb-3">
              <label
                for="alamatt"
                class="form-label"
                >Addres</label
              >
              <textarea
                name="alamatt"
                class="form-control"
                id="alamatt"
                cols="5"
                rows="2"
                :value="reserv.alamat"
                :disabled="
                  reserv.status === 'Paid' || reserv.status === 'Completed'
                "></textarea>
            </div>

            <div class="row mb-3">
              <div class="col-lg-5">
                <label
                  for="jmlorang"
                  class="form-label"
                  >Number of People</label
                >
                <input
                  type="number"
                  class="form-control"
                  id="jmlorang"
                  name="jmlorang"
                  :value="reserv.jumorang"
                  :disabled="
                    reserv.status === 'Paid' || reserv.status === 'Completed'
                  " />
              </div>
              <div class="col-lg-7">
                <label
                  for="rooms"
                  class="form-label"
                  >Select Room</label
                >
                <select
                  id="rooms"
                  class="form-select"
                  :value="reserv.room"
                  :disabled="
                    reserv.status === 'Paid' || reserv.status === 'Completed'
                  ">
                  <option value="Reguler">Reguler</option>
                  <option value="Vip Class">VIP Class</option>
                  <option value="Smoking Area">Smoking Area</option>
                </select>
              </div>
            </div>

            <div class="mb-3">
              <label
                for="kdcabs"
                class="form-label"
                >Select Branch</label
              >
              <select
                id="kdcabs"
                class="form-select"
                :value="reserv.kdcab"
                :disabled="
                  reserv.status === 'Paid' || reserv.status === 'Completed'
                ">
                <option
                  v-for="cab in branch"
                  :key="cab.id"
                  :value="cab.kdcab">
                  {{ cab.kdcab + ' ' + cab.company }}
                </option>
              </select>
              <span class="text-danger">{{ error.kdcab }}</span>
            </div>

            <div class="mb-3">
              <label
                for="jns-resv"
                class="form-label"
                >Reservation Type</label
              >
              <input
                type="text"
                id="initial-jeniss"
                :value="reserv.jenisrsv"
                hidden />
              <div
                class="btn-group form-control"
                role="group"
                aria-label="Basic radio toggle button group">
                <label
                  class="btn btn-outline-primary position-relative"
                  for="jns-resv1"
                  :class="{
                    active: selectedJeniss === 'Dine In',
                    'link-active':
                      reserv.jenisrsv === 'Dine In' && selectedJeniss == null,
                  }">
                  <input
                    type="radio"
                    name="jns-resv"
                    id="jns-resv1"
                    autocomplete="off"
                    value="Dine In"
                    style="
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                      opacity: 0;
                    "
                    @click="selectedJeniss = 'Dine In'"
                    :disabled="
                      reserv.status === 'Paid' || reserv.status === 'Completed'
                    " />
                  Dine In
                </label>

                <label
                  class="btn btn-outline-primary position-relative"
                  for="jns-resv2"
                  :class="{
                    active: selectedJeniss === 'Take Away',
                    'link-active':
                      reserv.jenisrsv === 'Take Away' && selectedJeniss == null,
                  }">
                  <input
                    type="radio"
                    name="jns-resv"
                    id="jns-resv2"
                    autocomplete="off"
                    value="Take Away"
                    style="
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                      opacity: 0;
                    "
                    @click="selectedJeniss = 'Take Away'"
                    :disabled="
                      reserv.status === 'Paid' || reserv.status === 'Completed'
                    " />
                  Take Away
                </label>
              </div>
              <span class="text-danger">{{ error.jenisreserv }}</span>
            </div>

            <div class="mb-3">
              <label
                for="jnsacara"
                class="form-label"
                >Event Type</label
              >
              <input
                type="text"
                class="form-control"
                id="jnsacara"
                name="jnsacara"
                :value="reserv.jenisacara"
                :disabled="
                  reserv.status === 'Paid' || reserv.status === 'Completed'
                " />
            </div>

            <div class="mb-3">
              <label
                for="eo"
                class="form-label"
                >EO CollaborationEO</label
              >
              <input
                type="number"
                id="initial-eos"
                :value="reserv.byeo"
                hidden />
              <div
                class="btn-group form-control"
                role="group"
                aria-label="Basic radio toggle button group">
                <label
                  class="btn btn-outline-primary position-relative"
                  for="eo"
                  :class="{
                    active: selectedEos === 1,
                    'link-active': reserv.byeo === 1 && selectedEos == null,
                  }">
                  <input
                    type="radio"
                    name="eo"
                    id="eo"
                    autocomplete="off"
                    value="1"
                    style="
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                      opacity: 0;
                    "
                    @click="selectedEos = 1"
                    :disabled="
                      reserv.status === 'Paid' || reserv.status === 'Completed'
                    " />
                  Yes
                </label>

                <label
                  class="btn btn-outline-primary position-relative"
                  for="eo"
                  :class="{
                    active: selectedEos === 0,
                    'link-active': reserv.byeo === 0 && selectedEos == null,
                  }">
                  <input
                    type="radio"
                    name="eo"
                    id="eo"
                    autocomplete="off"
                    value="0"
                    style="
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                      opacity: 0;
                    "
                    @click="selectedEos = 0"
                    :disabled="
                      reserv.status === 'Paid' || reserv.status === 'Completed'
                    " />
                  No
                </label>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-lg-6">
                <label
                  for="namaanak"
                  class="form-label"
                  >Child's Name</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="namaanak"
                  name="namaanak"
                  :value="reserv.nmanak"
                  :disabled="
                    reserv.status === 'Paid' || reserv.status === 'Completed'
                  " />
              </div>

              <div class="col-lg-6">
                <label
                  for="namaortu"
                  class="form-label"
                  >Parent's Name</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="namaortu"
                  name="namaortu"
                  :value="reserv.nmortu"
                  :disabled="
                    reserv.status === 'Paid' || reserv.status === 'Completed'
                  " />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-lg-6">
                <label
                  for="batascancel"
                  class="form-label"
                  >Deadline Cancel</label
                >
                <input
                  type="date"
                  class="form-control"
                  id="batascancel"
                  name="batascancel"
                  :value="reserv.lastcancel"
                  :disabled="reserv.status === 'Completed'" />
                <span class="text-danger">{{ error.lastcancel }}</span>
              </div>

              <div class="col-lg-6">
                <label
                  for="downpayments"
                  class="form-label"
                  >Down Payment</label
                >
                <input
                  type="number"
                  class="form-control"
                  id="downpayments"
                  name="downpayments"
                  :value="reserv.downpay"
                  :disabled="
                    reserv.status === 'Paid' || reserv.status === 'Completed'
                  " />
                <span class="text-danger">{{ error.downpay }}</span>
              </div>
            </div>
            <div class="mb-3">
              <label
                for="notes"
                class="form-label"
                >Note</label
              >
              <textarea
                name="notes"
                class="form-control"
                id="notes"
                cols="5"
                rows="2"
                :value="reserv.note"
                :disabled="
                  reserv.status === 'Paid' || reserv.status === 'Completed'
                "></textarea>
            </div>

            <fieldset disabled>
              <div class="row mb-3">
                <div class="col-lg-4">
                  <label
                    for="total"
                    class="form-label"
                    >Total</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="total"
                    name="total"
                    :value="reserv.total" />
                </div>

                <div class="col-lg-4">
                  <label
                    for="jns-pembayaran"
                    class="form-label"
                    >Type Payment</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="jns-pembayaran"
                    name="jns-pembayaran"
                    placeholder="Transfer/Card/Cash"
                    :value="reserv.jenisbyr" />
                </div>

                <div class="col-lg-4">
                  <label
                    for="denda"
                    class="form-label"
                    >Charge</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="denda"
                    name="denda"
                    :value="reserv.denda" />
                </div>
              </div>
            </fieldset>
            <input
              type="text"
              id="resv-id"
              :value="reserv.id"
              hidden />
            <button
              type="submit"
              @click="editReservasi"
              class="btn btn-success w-100 justify-content-center"
              :disabled="reserv.status === 'Completed'">
              Save
            </button>
            <div
              v-if="editStatusMessage === 200"
              class="text-success text-center fs-6 mt-3">
              {{ editMessage }}
            </div>
            <div
              v-if="editStatusMessage === 400"
              class="text-danger text-center fs-6 mt-3">
              {{ editMessage }}
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            @click="closeModal"
            class="btn btn-secondary"
            data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ModalEdit',
  props: ['reserv'],
  data() {
    return {
      selectedJeniss: null,
      selectedEos: null,
      branch: [],
      editMessage: '',
      editStatusMessage: null,
      errorcnt: 0,
      error: {
        tglreserv: null,
        atasnama: null,
        notelp: null,
        lastcancel: null,
        downpay: null,
        jamreserv: null,
        sdjam: null,
        jenisreserv: null,
        kdcab: null,
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.getBranch();
    }, 1000);
  },
  methods: {
    filterDate(value) {
      const date = new Date(value).toLocaleDateString();
      return date;
    },
    filterDateInput(value) {
      const date = new Date(value);
      let day = date.getDate();
      let month = date.getMonth() + 1;
      const year = date.getFullYear();

      if (month <= 9) month = '0' + String(month);
      if (day <= 9) day = '0' + String(day);

      const myDate = String(year) + '-' + String(month) + '-' + String(day);
      return myDate;
    },
    async getBranch() {
      try {
        const { data } = await axios.get('/branch', {
          headers: {
            Authorization: this.$store.getters.GET_AUTH_TOKEN,
          },
        });

        this.branch = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async editReservasi() {
      try {
        this.errorcnt = 0;
        this.error.atasnama = null;
        this.error.notelp = null;
        this.error.tglreserv = null;
        this.error.lastcancel = null;
        this.error.downpay = null;
        this.error.jamreserv = null;
        this.error.sdjam = null;
        this.error.jenisreserv = null;
        this.error.kdcab = null;

        if (!this.selectedEos)
          this.selectedEos = document.getElementById('initial-eos').value;
        if (!this.selectedJeniss)
          this.selectedJeniss = document.getElementById('initial-jeniss').value;

        if (!document.getElementById('tglresv').value) {
          this.error.tglreserv = 'Tanggal Reservasi harus diisi';
          this.errorcnt += 1;
        }
        if (!document.getElementById('atasnama').value) {
          this.error.atasnama = 'Atas nama harus diisi';
          this.errorcnt += 1;
        }
        if (!document.getElementById('notelp').value) {
          this.error.notelp = 'Nomor telepon harus diisi';
          this.errorcnt += 1;
        }
        if (!document.getElementById('batascancel').value) {
          this.error.lastcancel = 'Batas cancel harus diisi';
          this.errorcnt += 1;
        }
        if (!document.getElementById('downpayments').value) {
          this.error.downpay = 'Downpayment harus diisi';
          this.errorcnt += 1;
        }
        if (!document.getElementById('jamresv').value) {
          this.error.jamreserv = 'Jam reservasi harus diisi';
          this.errorcnt += 1;
        }
        if (!document.getElementById('sdjam').value) {
          this.error.sdjam = 's/d jam reservasi harus diisi';
          this.errorcnt += 1;
        }
        if (!this.selectedJeniss) {
          this.error.jenisreserv = 'Jenis reservasi harus diisi';
          this.errorcnt += 1;
        }
        if (!document.getElementById('kdcabs').value) {
          this.error.kdcab = 'Kode cabang harus diisi';
          this.errorcnt += 1;
        }

        if (this.errorcnt > 0) {
          throw new Error('');
        }

        const reservasi = {
          tglreserv: document.getElementById('tglresv').value,
          jamreserv: document.getElementById('jamresv').value,
          sdjamrsv: document.getElementById('sdjam').value,
          atasnama: document.getElementById('atasnama').value,
          notelp: document.getElementById('notelp').value,
          alamat: document.getElementById('alamatt').value,
          jumorang: parseInt(document.getElementById('jmlorang').value),
          room: document.getElementById('rooms').value,
          jenisrsv: this.selectedJeniss,
          jenisacara: document.getElementById('jnsacara').value,
          byeo: parseInt(this.selectedEos),
          nmanak: document.getElementById('namaanak').value,
          nmortu: document.getElementById('namaortu').value,
          lastcancel: document.getElementById('batascancel').value,
          downpay: parseFloat(document.getElementById('downpayments').value),
          kdcab: document.getElementById('kdcabs').value,
          note: document.getElementById('notes').value,
          id: document.getElementById('resv-id').value,
          useredit: this.$store.getters.GET_AUTH_INFO.id,
          tgledit: new Date().toLocaleDateString,
        };

        const { data } = await axios.put('/reservasi', reservasi, {
          headers: {
            Authorization: this.$store.getters.GET_AUTH_TOKEN,
          },
        });
        console.log('200, edit success');
        this.editMessage = 'Edited reservation successfully!';
        this.editStatusMessage = 200;
        setTimeout(() => {
          window.location.reload();
          this.editMessage = '';
          this.editStatusMessage = null;
        }, 1000);
      } catch (error) {
        console.log(error);
        this.editMessage = 'Edited reservation failed!!!';
        this.editStatusMessage = 400;
      }
    },
    async closeModal() {
      this.editMessage = '';
      this.editStatusMessage = null;
    },
  },
};
</script>

<style scoped>
.link-active {
  background-color: #0d6efd !important;
  color: white;
}
</style>
