<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="ModalBatal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="ModalBatalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div
        class="modal-content"
        style="
          height: 90vh;
          overflow-y: scroll;
          overflow-x: hidden;
          z-index: 1000;
          box-shadow: 9px 9px 6px -3px rgba(0, 0, 0, 0.1);
        ">
        <div class="modal-header">
          <h1
            class="modal-title fs-5"
            id="ModalBatalLabel">
            Form Cancel Reservation
          </h1>
          <button
            type="button"
            @click="closeModal"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <fieldset disabled>
              <div class="row mb-3">
                <div class="col-lg-6">
                  <label
                    for="tgl-input"
                    class="form-label"
                    >Input Date</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="tgl-input"
                    name="tgl-input"
                    :value="filterDate(reserv.tglinput)" />
                </div>

                <div class="col-lg-6">
                  <label
                    for="input-oleh"
                    class="form-label"
                    >Input By.</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="input-oleh"
                    name="input-oleh"
                    :value="reserv.userid" />
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-lg-6">
                  <label
                    for="tgl-resv"
                    class="form-label"
                    >Reservation Date</label
                  >
                  <input
                    type="date"
                    class="form-control"
                    id="tgl-resv"
                    name="tgl-resv"
                    :value="filterDateInput(reserv.tglreserv)" />
                </div>
                <div class="col-lg-3">
                  <label
                    for="jam-resv"
                    class="form-label"
                    >Start Time</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="jam-resv"
                    name="jam-resv"
                    :value="reserv.jamreserv" />
                </div>
                <div class="col-lg-3">
                  <label
                    for="sd"
                    class="form-label"
                    >End Time</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="sd"
                    name="sd"
                    :value="reserv.sdjamrsv" />
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-lg-6">
                  <label
                    for="atas-nama"
                    class="form-label"
                    >On Behalf</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="atas-nama"
                    name="atas-nama"
                    :value="reserv.atasnama" />
                </div>
                <div class="col-lg-6">
                  <label
                    for="telp"
                    class="form-label"
                    >Phone Number</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="telp"
                    name="telp"
                    :value="reserv.notelp" />
                </div>
              </div>

              <div class="mb-3">
                <label
                  for="alamat"
                  class="form-label"
                  >Addres</label
                >
                <textarea
                  name="alamat"
                  class="form-control"
                  id="alamat"
                  cols="5"
                  rows="2"
                  :value="reserv.alamat"></textarea>
              </div>

              <div class="row mb-3">
                <div class="col-lg-5">
                  <label
                    for="jml-orang"
                    class="form-label"
                    >Number of People</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="jml-orang"
                    name="jml-orang"
                    :value="reserv.jumorang" />
                </div>
                <div class="col-lg-7">
                  <label
                    for="room"
                    class="form-label"
                    >Select Room</label
                  >
                  <select
                    id="room"
                    class="form-select"
                    :value="reserv.room">
                    <option value="Reguler">Reguler</option>
                    <option value="Vip Class">VIP Class</option>
                    <option value="Smoking Area">Smoking Area</option>
                  </select>
                </div>
              </div>

              <div class="mb-3">
                <label
                  for="kdcab"
                  class="form-label"
                  >Select Branch</label
                >
                <select
                  id="kdcab"
                  class="form-select"
                  :value="reserv.kdcab">
                  <option
                    v-for="cab in branch"
                    :key="cab.id"
                    :value="cab.kdcab">
                    {{ cab.kdcab + ' ' + cab.company }}
                  </option>
                </select>
              </div>

              <div class="mb-3">
                <label
                  for="jns-resv"
                  class="form-label"
                  >Reservation Type</label
                >
                <div
                  class="btn-group form-control"
                  role="group"
                  aria-label="Basic radio toggle button group">
                  <label
                    class="btn btn-outline-primary position-relative"
                    for="jns-resv1"
                    :class="{
                      active: selectedJenis === 'Dine In',
                      'link-active':
                        reserv.jenisrsv === 'Dine In' && selectedJenis == null,
                    }">
                    <input
                      type="radio"
                      name="jns-resv"
                      id="jns-resv1"
                      autocomplete="off"
                      value="Dine In"
                      style="
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                      "
                      @click="selectedJenis = 'Dine In'" />
                    Dine In
                  </label>

                  <label
                    class="btn btn-outline-primary position-relative"
                    for="jns-resv2"
                    :class="{
                      active: selectedJenis === 'Take Away',
                      'link-active':
                        reserv.jenisrsv === 'Take Away' &&
                        selectedJenis == null,
                    }">
                    <input
                      type="radio"
                      name="jns-resv"
                      id="jns-resv2"
                      autocomplete="off"
                      value="Take Away"
                      style="
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                      "
                      @click="selectedJenis = 'Take Away'" />
                    Take Away
                  </label>
                </div>
              </div>

              <div class="mb-3">
                <label
                  for="jns-acara"
                  class="form-label"
                  >Event Type</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="jns-acara"
                  name="jns-acara"
                  :value="reserv.jenisacara" />
              </div>

              <div class="mb-3">
                <label
                  for="eo"
                  class="form-label"
                  >EO Collaboration</label
                >
                <div
                  class="btn-group form-control"
                  role="group"
                  aria-label="Basic radio toggle button group">
                  <label
                    class="btn btn-outline-primary position-relative"
                    for="eo1"
                    :class="{
                      active: selectedEo === 'Yes',
                      'link-active': reserv.byeo === 1 && selectedEo == null,
                    }">
                    <input
                      type="radio"
                      name="eo"
                      id="eo1"
                      autocomplete="off"
                      value="Yes"
                      style="
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                      "
                      @click="selectedEo = 'Yes'" />
                    Yes
                  </label>

                  <label
                    class="btn btn-outline-primary position-relative"
                    for="eo2"
                    :class="{
                      active: selectedEo === 'No',
                      'link-active': reserv.byeo === 0 && selectedEo == null,
                    }">
                    <input
                      type="radio"
                      name="eo"
                      id="eo2"
                      autocomplete="off"
                      value="No"
                      style="
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                      "
                      @click="selectedEo = 'No'" />
                    No
                  </label>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-lg-6">
                  <label
                    for="nama-anak"
                    class="form-label"
                    >Child's Name</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="nama-anak"
                    name="nama-anak"
                    :value="reserv.nmanak" />
                </div>

                <div class="col-lg-6">
                  <label
                    for="nama-ortu"
                    class="form-label"
                    >Parent's Name</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="nama-ortu"
                    name="nama-ortu"
                    :value="reserv.nmortu" />
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-lg-6">
                  <label
                    for="batas-cancel"
                    class="form-label"
                    >Deadline Cancel</label
                  >
                  <input
                    type="date"
                    class="form-control"
                    id="batas-cancel"
                    name="batas-cancel"
                    :value="reserv.lastcancel" />
                </div>

                <div class="col-lg-6">
                  <label
                    for="downpayment"
                    class="form-label"
                    >Down Payment</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="downpayment"
                    name="downpayment"
                    :value="reserv.downpay" />
                </div>
              </div>
            </fieldset>
            <div class="row mb-3">
              <div class="col-lg-4">
                <fieldset disabled>
                  <label
                    for="total"
                    class="form-label"
                    >Total</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="total"
                    name="total"
                    :value="reserv.total" />
                </fieldset>
              </div>

              <div class="col-lg-4">
                <fieldset disabled>
                  <label
                    for="jns-pembayaran"
                    class="form-label"
                    >Payment Type</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="jns-pembayaran"
                    name="jns-pembayaran"
                    placeholder="Transfer/Card/Cash"
                    :value="reserv.jenisbyr" />
                </fieldset>
              </div>
              <input
                type="text"
                name="idReserv"
                id="idReserv"
                :value="reserv.id"
                hidden />

              <div class="col-lg-4">
                <label
                  for="denda"
                  class="form-label"
                  >Charge</label
                >
                <input
                  type="number"
                  class="form-control"
                  id="denda"
                  name="denda"
                  v-model="denda" />
              </div>
            </div>
            <button
              type="submit"
              @click="deleteResv"
              class="btn btn-success w-100 justify-content-center">
              Submit
            </button>
            <div
              v-if="batalStatusMessage === 200"
              class="text-success text-center fs-6 mt-3">
              {{ batalMessage }}
            </div>
            <div
              v-if="batalStatusMessage === 400"
              class="text-danger text-center fs-6 mt-3">
              {{ batalMessage }}
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            @click="closeModal"
            class="btn btn-secondary"
            data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ModalBatal',
  props: ['reserv'],
  data() {
    return {
      selectedJenis: null,
      selectedEo: null,
      branch: [],
      denda: 0,
      batalMessage: '',
      batalStatusMessage: null,
    };
  },
  mounted() {
    setTimeout(() => {
      this.getBranch();
    }, 1000);
  },
  methods: {
    filterDate(value) {
      const date = new Date(value).toLocaleDateString();
      return date;
    },
    filterDateInput(value) {
      const date = new Date(value);
      let day = date.getDate();
      let month = date.getMonth() + 1;
      const year = date.getFullYear();

      if (month <= 9) month = '0' + String(month);
      if (day <= 9) day = '0' + String(day);

      const myDate = String(year) + '-' + String(month) + '-' + String(day);
      return myDate;
    },
    async getBranch() {
      try {
        const { data } = await axios.get('/branch', {
          headers: {
            Authorization: this.$store.getters.GET_AUTH_TOKEN,
          },
        });

        this.branch = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async deleteResv() {
      try {
        const { data } = await axios.delete('/reservasi', {
          headers: {
            Authorization: this.$store.getters.GET_AUTH_TOKEN,
          },
          data: {
            userid: this.$store.getters.GET_AUTH_INFO.id,
            id: document.getElementById('idReserv').value,
            denda: this.denda,
          },
        });

        this.batalMessage = 'Cancel reservation successfully!';
        this.batalStatusMessage = 200;

        setTimeout(() => {
          window.location.reload();
          this.batalMessage = '';
          this.batalStatusMessage = null;
        }, 1000);
      } catch (error) {
        console.log(error);
        this.batalMessage = 'Cancel reservation failed!!!';
        this.batalStatusMessage = 400;
        console.log('400, Cancel reservation failed!!!');
      }
    },
    async closeModal() {
      this.batalMessage = '';
      this.batalStatusMessage = null;
    },
  },
};
</script>

<style scoped>
.link-active {
  background-color: #0d6efd !important;
  color: white;
}
</style>
