<template>
  <!-- DataTales Example -->
  <div class="card shadow mb-4">
    <div class="card-header py-3">
      <h6 class="m-0 font-weight-bold text-success">
        Table Reservation
        {{ role }}
      </h6>
      <button
        v-if="
          this.$store.getters.GET_AUTH_INFO.role === 4 ||
          this.$store.getters.GET_AUTH_INFO.role === 1
        "
        class="btn btn-primary"
        style="position: absolute; top: 5px; right: 10px"
        @click="downloadTransaction">
        Download Transaction
      </button>
    </div>
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-lg-8">
          <!-- Button trigger modal -->
          <button
            type="button"
            class="btn btn-success"
            data-bs-toggle="modal"
            data-bs-target="#ModalCreate">
            Create New Reservation
          </button>
        </div>
        <div class="col-lg-4">
          <input
            v-model="params.search"
            class="form-control me-2 is-valid"
            type="search"
            placeholder="Search"
            aria-label="Search" />
        </div>
      </div>
      <div class="table-responsive">
        <vue3-datatable
          :search="params.search"
          :rows="rows"
          :columns="cols"
          :totalRows="total_rows"
          :sortable="true"
          :sortColumn="params.sort_column"
          :sortDirection="params.sort_direction">
          <template #edit="data">
            <div class="text-center">
              <a
                class="btn btn-primary text-center"
                data-bs-toggle="modal"
                data-bs-target="#ModalEdit"
                @click="showEdit(data.value)">
                <i class="ri-edit-box-fill fs-6"></i>
              </a>
            </div>
          </template>
          <template #detail="data">
            <div class="text-center">
              <a
                class="btn btn-info text-center"
                @click="showDetail(data.value)"
                style="color: #fff">
                <i class="ri-newspaper-fill"></i>
              </a>
            </div>
          </template>
          <template #batal="data">
            <div
              class="text-center"
              style="
                display: flex;
                flex-direction: row;
                justify-content: center;
                gap: 10px;
              ">
              <a
                v-if="
                  data.value.status !== 'Paid' &&
                  data.value.status !== 'Completed'
                "
                class="btn btn-danger text-center"
                data-bs-toggle="modal"
                data-bs-target="#ModalBatal"
                @click="showEdit(data.value)">
                <i class="ri-calendar-close-fill"></i>
              </a>

              <a
                v-if="data.value.status === 'Paid'"
                class="btn btn-success text-center"
                data-bs-toggle="modal"
                data-bs-target="#ModalConfirm"
                @click="selected = data.value">
                <i class="ri-check-double-fill"></i>
              </a>
            </div>
          </template>

          <template
            #cetaks="data"
            style="position: relative">
            <input
              type="text"
              :id="'payment-' + data.value.id"
              :value="
                'https://reservasi.tamani-pos.online/reservation-payment/' +
                data.value.id
              "
              style="position: absolute; top: 0; opacity: 0; width: 50px" />
            <div
              class="text-center"
              style="
                display: flex;
                flex-direction: row;
                justify-content: center;
                gap: 10px;
              ">
              <a
                v-if="data.value.status === 'Pending'"
                class="btn btn-secondary text-center"
                data-bs-toggle="modal"
                @click="showEdit(data.value)"
                data-bs-target="#ModalCetak">
                <i class="ri-printer-fill"></i>
              </a>

              <a
                v-if="data.value.status === 'Pending Payment'"
                class="btn btn-dark text-center"
                @click="copyPaymentLink(data.value.id)">
                <i class="ri-clipboard-fill"></i>
              </a>
            </div>
          </template>
        </vue3-datatable>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="ModalConfirm"
    data-bs-backdrop="false"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="ModalConfirmLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h1
            class="modal-title fs-5"
            id="ModalConfirmLabel">
            Confirmation
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <h6>Are you sure want to complete this reservation ?</h6>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-warning"
            @click="completeReservation(selected)">
            Confirm
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Create -->
  <ModalCreate></ModalCreate>

  <!-- Modal Edit -->
  <ModalEdit :reserv="dataku"></ModalEdit>

  <!-- Modal Batal -->
  <ModalBatal :reserv="dataku"></ModalBatal>

  <!-- Modal Cetak -->
  <ModalCetak :reserv="dataku"></ModalCetak>
</template>

<script>
import ModalCreate from './ModalCreate.vue';
import ModalEdit from './ModalEdit.vue';
import ModalBatal from './ModalBatal.vue';
import ModalCetak from './ModalCetak.vue';
import axios from 'axios';

export default {
  name: 'DataTable',
  components: {
    ModalCreate,
    ModalEdit,
    ModalBatal,
    ModalCetak,
  },
  data() {
    return {
      dataku: {},
      reserv: [],
      selected: {},
      role: null,
    };
  },
  mounted() {
    this.role = this.$store.getters.GET_AUTH_INFO.role;
    this.getReservasi();
  },
  methods: {
    copyPaymentLink(id) {
      const text = document.getElementById(`payment-${id}`);
      text.select();

      try {
        document.execCommand('copy');
        alert('Payment Link Copied', text.value);
      } catch (err) {
        alert('Unable to copy payment link to clipboard', err);
      }
    },
    showEdit(reservasi) {
      const selected = this.reserv.filter((data) => {
        return data.id === reservasi.id;
      });
      this.dataku = selected[0];
    },
    showDetail(reservasi) {
      this.$router.push({ name: 'detail', params: { id: reservasi.id } });
    },
    async completeReservation(data) {
      try {
        const body = {
          resvid: data.id,
        };

        await axios
          .post('/reservation-complete', body, {
            headers: {
              Authorization: this.$store.getters.GET_AUTH_TOKEN,
            },
          })
          .then(() => {
            window.location.reload();
          });
      } catch (error) {
        console.log(error);
      }
    },
    async getReservasi() {
      try {
        const { data } = await axios.get('/reservasi', {
          headers: {
            Authorization: this.$store.getters.GET_AUTH_TOKEN,
            User: this.$store.getters.GET_AUTH_INFO.id,
            Filter: 'Upcoming',
          },
        });

        this.reserv = data.data;
      } catch (error) {
        console.log(error);
        if (error.response.status == 401) {
          store
            .dispatch('LOGOUT')
            .then(() => {
              window.location.href = '/admin-login';
            })
            .catch(() => {
              window.location.href = '/admin-login';
            });
        }
      }
    },
    async downloadTransaction() {
      try {
        const server =
          'https://tamaniservices.my.id/api/v1/download-transaction';
        const response = await fetch(server, {
          method: 'GET',
          headers: {
            'Content-Type':
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            Authorization: this.$store.getters.GET_AUTH_TOKEN,
          },
        });

        if (response.status === 404) {
          alert('No transactions to export.');
          return;
        }

        if (!response.ok) {
          alert(`Failed to download XLSX file. Status: ${response.status}`);
          return;
        }

        const blob = await response.blob();
        const downloadLink = document.createElement('a');
        const objectURL = URL.createObjectURL(blob);

        downloadLink.href = objectURL;
        downloadLink.download = `Transaction-${new Date().toLocaleDateString()}.xlsx`;

        document.body.appendChild(downloadLink);
        downloadLink.click();

        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(objectURL);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<script setup>
import { ref, onMounted, reactive } from 'vue';
import Vue3Datatable from '@bhplugin/vue3-datatable';
import '@bhplugin/vue3-datatable/dist/style.css';
import store from '../store';

onMounted(() => {
  getRsv();
});

const params = reactive({
  search: '',
  sort_column: 'noreserv',
  sort_direction: 'asc',
});

const total_rows = ref(0);

const rows = ref(null);

const cols =
  ref([
    { field: 'noreserv', title: 'No Resv', width: '20px' },
    { field: 'atasnama', title: 'On Behalf' },
    { field: 'tglinput', title: 'Input Date', type: 'date', width: '50px' },
    {
      field: 'tglreserv',
      title: 'Reservation Date',
      type: 'date',
      width: '50px',
    },
    { field: 'jenisrsv', title: 'Reservation Type', width: '50px' },
    { field: 'status', title: 'Status', width: '50px' },
    { field: 'edit', title: 'Edit', width: '20px' },
    { field: 'detail', title: 'Detail', width: '20px' },
    { field: 'batal', title: 'Action', width: '20px' },
    { field: 'cetaks', title: 'Payment', width: '20px' },
  ]) || [];

const getRsv = async () => {
  try {
    const { data } = await axios.get('/reservasi', {
      headers: {
        Authorization: store.getters.GET_AUTH_TOKEN,
        User: store.getters.GET_AUTH_INFO.id,
        Filter: 'Upcoming',
      },
    });

    const response = await data.data;
    const resv = [];
    response.forEach((el) => {
      if (el.status !== 'Cancel') {
        const responses = {
          id: el.id,
          noreserv: el.noreserv,
          atasnama: el.atasnama,
          tglinput: new Date(el.tglinput).toLocaleDateString(),
          tglreserv: new Date(el.tglreserv).toLocaleDateString(),
          jenisrsv: el.jenisrsv,
          status: el.status,
          cetak: el.cetak,
        };
        resv.push(responses);
      }
    });
    rows.value = resv;
    total_rows.value = response.total;
  } catch (error) {
    console.log(error);
    if (error.response.status == 401) {
      store
        .dispatch('LOGOUT')
        .then(() => {
          window.location.href = '/login';
        })
        .catch(() => {
          window.location.href = '/login';
        });
    }
  }
};
</script>

<style scoped>
.form-control.is-valid {
  background-image: none !important;
}
</style>
