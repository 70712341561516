<script setup></script>
<template>
  <!-- DataTales Customer -->
  <div class="card shadow mb-4">
    <div class="card-header py-3">
      <h6 class="m-0 font-weight-bold text-success">Customer Reservation</h6>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table
          class="table table-bordered"
          id="dataTable"
          aria-describedby="Table Customer">
          <thead>
            <tr class="text-center">
              <th>On Behalf</th>
              <th>Input Date</th>
              <th>Reservation Date</th>
              <th>Reservation Type</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ rsvdetail.atasnama }}</td>
              <td>{{ new Date(rsvdetail.tglinput).toLocaleDateString() }}</td>
              <td>{{ new Date(rsvdetail.tglreserv).toLocaleDateString() }}</td>
              <td>{{ rsvdetail.jenisrsv }}</td>
              <td>{{ rsvdetail.status }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- DataTales Item Menu -->
  <div class="card shadow mb-4">
    <div class="card-header py-3">
      <h6 class="m-0 font-weight-bold text-success">Order Menu Items</h6>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table
          class="table table-bordered"
          id="dataTable"
          aria-describedby="Table Detail Menu">
          <thead>
            <tr class="text-center">
              <th style="width: 10%">No</th>
              <th style="width: 80%">Item</th>
              <th style="width: 10%">Qtty</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="detail in rsvdetail.Rsvdtl"
              :key="detail.no">
              <td class="text-center">{{ detail.urut }}</td>
              <td
                v-if="detail.main_food === true"
                style="font-weight: 500">
                <div class="row">
                  <div class="col-lg-11">
                    {{ detail.kode }} - {{ detail.name }}
                  </div>
                  <div class="col-lg-1">
                    <div
                      v-if="rsvdetail.status !== 'Completed'"
                      class="btn btn-danger"
                      style="scale: 75%"
                      @click="hapusPesanan(detail.node)">
                      <i class="ri-close-fill"></i>
                    </div>
                  </div>
                </div>
              </td>
              <td v-if="detail.main_food === false">
                <span style="margin-left: 20px"
                  >{{ detail.kode }} - {{ detail.name }}</span
                >
              </td>
              <td class="text-center">{{ detail.qorder }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer">
      <div class="row mt-3 mb-3">
        <div class="col-lg-6">
          <router-link
            class="btn btn-danger"
            aria-current="page"
            :to="{ name: 'reservasi' }">
            <i class="ri-arrow-left-line"></i> Back
          </router-link>
        </div>
        <div class="col-lg-6 d-flex justify-content-end">
          <router-link
            v-if="rsvdetail.status !== 'Completed'"
            class="btn btn-success"
            aria-current="page"
            :to="{ name: 'menu', params: { id: rsvdetail.id } }">
            <i class="ri-shopping-cart-fill"></i> Add Item
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'TableDetail',
  props: ['rsvdetail'],
  components: {},
  data() {
    return {};
  },
  methods: {
    async hapusPesanan(urutDetail) {
      try {
        const items = {
          node: urutDetail,
          idreserv: this.$route.params.id,
        };

        await axios.delete('/reservasi-detail', {
          headers: {
            Authorization: this.$store.getters.GET_AUTH_TOKEN,
          },
          data: items,
        });

        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
